import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import L from 'leaflet';

import useAppSelector from '@hooks/useAppSelector';
import { useAsync } from '@hooks';
import { fetchGeofenceList } from '@api/common/geofence';
import errorMarker from '@asset/images/marker_green_10.png';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { convertState } from '@reducer/Common/processSocketData';
import { Map, RotatedImageOverlay } from '@components';
import { isIn } from '@util/mappInfo';

import './Components/leaflet-canvas-markers';
import WidgetCard from '../../Components/WidgetCard';
import AllTarget from './Components/AllTarget';
import { useSettings } from '../../util/useSettings';
import { setGeofenceInfo, setRealTimeLog, setSelectedFloor } from './massRealTimeLocationSlice';
import { useMassRealTimeLocationStatusContext, useMassRealTimeLocationStatusDispatch } from './index';

const MassRealTimeLocationStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const { categoryToImg, categoryList } = useAppSelector(state => state.CategoryInfo);
    const { floorInfo, selectedFloor, realTimeLog } = useMassRealTimeLocationStatusContext();
    const dispatch = useMassRealTimeLocationStatusDispatch();

    const [errorImg, setErrorImg] = useState({});
    const [loadImg, setLoadImg] = useState([]);

    const canvasRef = useRef(L.canvasIconLayer({}));
    const widgetRef = useRef();
    const mapRef = useRef();
    const currFloorTags = useRef({});

    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: response => {
            if (response) {
                dispatch(setGeofenceInfo({ geofenceInfo: response.rows ?? [] }));
            }
        },
    });

    const initMarkers = () => {
        if (markerRef.current?.length) {
            canvasRef.current.removeMarkers(markerRef.current, true);
            markerRef.current = null;
        }
    };

    useEffect(() => {
        let unionImgLength = Object.keys(errorImg).length + loadImg.length;
        if (unionImgLength === categoryList.length) {
            if (settings.floor) {
                dispatch(setSelectedFloor(settings.floor));
            }
        }
        return () => {
            initMarkers();
        };
    }, [errorImg, loadImg]);

    useEffect(() => {
        if (selectedFloor) {
            // socket 데이터 초기화
            currFloorTags.current = {};

            // 층 값 변경시, 기존 값 삭제
            initMarkers();
            getGeofenceList({ floor: selectedFloor });
        }
    }, [selectedFloor]);

    const markerRef = useRef();
    const { markerConfigValue } = useAppSelector(state => state.AppConfig);

    const createMarker = log => {
        const { defaultSize, minSize, maxSize } = markerConfigValue;

        const zoom = mapRef.current.leafletElement.getZoom();
        let size = defaultSize * Math.pow(1 / 2, 18 - zoom);
        size = Math.min(Math.max(size, minSize), maxSize);

        initMarkers();

        markerRef.current = log.data.reduce((acc, curr) => {
            const { location, target } = curr;
            let imgUrl = '';

            if (errorImg[target.categoryCode]) {
                imgUrl = errorMarker;
            } else {
                imgUrl = `${categoryToImg[target.categoryCode]}`;
            }

            let marker = L.marker([location.lat, location.lng], {
                icon: L.icon({
                    iconUrl: imgUrl,
                    iconSize: [size, size],
                    iconAnchor: [size / 2, size],
                    text: `${target.targetName}`,
                    textFont: '10px bold',
                }),
            });
            acc.push(marker);
            return acc;
        }, []);
        canvasRef.current.addMarkers(markerRef.current);
    };

    const customMarker = useCallback(
        log => {
            createMarker(log);
        },
        [realTimeLog],
    );

    useEffect(() => {
        if (mapRef.current?.leafletElement) {
            if (canvasRef.current._canvas && canvasRef.current._map) {
                customMarker(realTimeLog);
            } else {
                // 초기세팅 : 캔버스에 맵 추가
                canvasRef.current.addTo(mapRef.current.leafletElement);
            }
        }
    }, [realTimeLog]);

    const filterConfig = useMemo(() => {
        const resultConfig = {};

        if (selectedFloor) {
            resultConfig.$or = [
                { currentFloor: { floorId: selectedFloor } },
                { previousFloor: { floorId: selectedFloor } },
            ];
        } else {
            resultConfig.currentFloor = { noneFloor: true };
        }
        return resultConfig;
    }, [selectedFloor]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig,
        handler: data => {
            const {
                target,
                targetState,
                currentFloor,
                targetCategory: { categoryCode },
                latlng: { lat, lng },
            } = data;
            const { lostSignal } = convertState(targetState);
            if (!lostSignal && filterConfig?.$or?.[0]?.currentFloor?.floorId === currentFloor.floorId) {
                currFloorTags.current[target.targetNum] = {
                    ...data,
                    target: { ...target, categoryCode: categoryCode },
                    location: {
                        lat,
                        lng,
                        latLng: [lat, lng],
                    },
                    floorInOutState: {
                        floorId: currentFloor.floorId,
                        floorName: currentFloor.floorName,
                        isIn: isIn(currentFloor.inOutState),
                        inTime: currentFloor.inUnixTime,
                        stayTime: currentFloor.stayUnixTime,
                        outTime: currentFloor.outUnixTime,
                    },
                };
            } else if (currFloorTags.current[target.targetNum]) {
                currFloorTags.current[target.targetNum] = null;
                delete currFloorTags.current[target.targetNum];
            }
            const tagList = Object.values(currFloorTags.current);
            dispatch(
                setRealTimeLog({
                    count: tagList.length,
                    data: tagList,
                }),
            );
        },
    });
    return (
        <WidgetCard bodyClassName={'p-1'} widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            {categoryList.map(({ normalIconURL, categoryCode }, i) => {
                return (
                    <img
                        key={categoryCode + i}
                        onLoad={() => {
                            setLoadImg([...loadImg, categoryCode]);
                        }}
                        hidden
                        src={normalIconURL}
                        alt={'marker'}
                        onError={() => {
                            setErrorImg({ ...errorImg, [categoryCode]: categoryCode });
                        }}
                    />
                );
            })}
            <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                <AllTarget widgetRef={widgetRef} mapRef={mapRef} />
                {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                    <RotatedImageOverlay
                        key={floorInfo.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={() => {
                            const map = mapRef.current.leafletElement;
                            if (map) {
                                map.fitBounds(floorInfo.bounds);
                            }
                        }}
                    />
                )}
            </Map>
            {children}
        </WidgetCard>
    );
};

export default MassRealTimeLocationStatusContainer;
