import React, { useMemo, useState } from 'react';
import { Table } from '@components';
import { useTranslation } from '@util/hooks';
import styled from 'styled-components';
import OutstandingDetail from './OutstandingDetail';
import { Data, Row } from '../hooks/useAmountList';
import useModal from '../../../util/useModal';
import { formatDateByColumn, formatPhoneNumber } from '../../../util/format';

interface Props {
    data: Data;
    onPageChange: (page: number) => void;
    refreshList: () => void;
}

const OutstandingAmountList = ({ data, onPageChange, refreshList }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [selectedRow, setSelectedRow] = useState<Row>();
    const [isOpen, handleToggle, handleOpen] = useModal(); // 알림톡 이력 상세 모달

    const columns = useMemo(
        () => [
            {
                Header: t('Patient number'),
                accessor: 'patientId',
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
            },
            {
                Header: t('Phone Number'),
                accessor: 'phoneNum',
                Cell: ({ value }: { value: string }) => {
                    return formatPhoneNumber(value) || '-';
                },
            },
            {
                Header: t('Total outstanding amount'),
                accessor: 'amount',
                Cell: ({ value }: { value: string }) => {
                    return <span>{Number(value).toLocaleString()}</span>;
                },
            },
            {
                Header: t('Notification Send History'),
                accessor: 'historyCount',
                Cell: ({ value }: { value: number }) => {
                    return (
                        <span>
                            {value ?? 0}
                            {t(' times')}
                        </span>
                    );
                },
            },
            {
                Header: t('Last send date'),
                Cell: ({ row }: { row: { original: Row } }) => {
                    return <span>{formatDateByColumn(row, 'lastSendingDate') || '-'}</span>;
                },
            },
        ],
        [data.rows, t],
    ) as any;

    const handleTrClick = (row: Row) => {
        setSelectedRow(row);
        handleOpen();
    };

    return (
        <>
            <TableWrapper>
                <Table columns={columns} data={data} onPageChange={onPageChange} onTrClick={handleTrClick} />
            </TableWrapper>
            {isOpen && selectedRow && (
                <OutstandingDetail
                    isOpen={isOpen}
                    toggleModal={handleToggle}
                    patientInfo={selectedRow}
                    refreshList={refreshList}
                />
            )}
        </>
    );
};

const TableWrapper = styled.div`
    height: 600px;
`;

export default OutstandingAmountList;
