import React from 'react';
import cx from 'classnames';
import NoDataBlock from '../../../Components/NoDataBlock';
import { Table, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import styles from '@asset/pnt/widgets/sh/UsageSensorReport.module.scss';
type SpanConfig = {
    column: string;
    rowSpan: number;
    start: number;
};

export type Columns = {
    Header: string;
    accessor: string;
    Cell?: (data: any) => string | number | JSX.Element;
    className?: string;
    width?: number;
};

type Rows = {
    [key: string]: any;
};

type RowSpans = { column: string; Header: string | JSX.Element; start?: number; name?: string; groupKey: string };

interface Props {
    columns: Columns[];
    rows: Rows[];
    rowSpans?: RowSpans[];
    lastRow?: string[] | JSX.Element[];
}

const CustomTable = ({ columns = [], rows = [], rowSpans = [], lastRow = [] }: Props) => {
    // Calculate row span configurations
    const calculateRowSpans = (rows: Rows[], rowSpans: RowSpans[]) => {
        const spanConfigs: any = [];

        rowSpans.forEach(({ column, Header, start: rowStart, groupKey, name }) => {
            let count = 0;
            let start: any = null;
            rows.forEach((row, index: number) => {
                if ((row[column] === Header && row[groupKey] === name) || rowStart !== undefined) {
                    if (start === null) {
                        start = index;
                    }
                    count++;
                } else if (start !== null) {
                    spanConfigs.push({ column, start, rowSpan: count });
                    start = null;
                    count = 0;
                }
            });
            if (start !== null) {
                spanConfigs.push({ column, start, rowSpan: count });
            }
        });

        return spanConfigs;
    };

    const spanConfigs = calculateRowSpans(rows, rowSpans || []);

    return (
        <>
            {rows.length ? (
                <div className={styles['tableWrapper']}>
                    <table className={cx('table')}>
                        <thead className={styles['headerItem']}>
                            <tr className={'tr'}>
                                {columns.map((header, index) => (
                                    <th
                                        key={index}
                                        className={'th'}
                                        style={{
                                            width: header?.width || '',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {header.Header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className={cx(styles['borderItem'], 'tbody')}>
                            {rows.map((row, rowIndex) => (
                                <tr key={rowIndex} className={'tr border-depth-9'}>
                                    {columns.map((header, cellIndex) => {
                                        const cellData = row[header.accessor as keyof Rows];
                                        const displayData = header.Cell ? header.Cell(cellData) : cellData;
                                        const rowspanConfig = spanConfigs.find(
                                            (config: SpanConfig) =>
                                                config.column === header.accessor && rowIndex === config.start,
                                        );
                                        if (rowspanConfig) {
                                            return (
                                                <td
                                                    key={cellIndex}
                                                    rowSpan={rowspanConfig.rowSpan}
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                    className={cx('td', header.className)}
                                                >
                                                    <div className={'d-flex justify-content-center'}>{displayData}</div>
                                                </td>
                                            );
                                        } else if (
                                            spanConfigs.some(
                                                (config: SpanConfig) =>
                                                    config.column === header.accessor &&
                                                    rowIndex > config.start &&
                                                    rowIndex < config.start + config.rowSpan,
                                            )
                                        ) {
                                            return null; // Skip rendering cells covered by rowspan
                                        } else {
                                            // 마지막 셀 스타일 여부
                                            return (
                                                <td key={cellIndex} className={cx('td', header.className)}>
                                                    <div className={cx(`d-flex justify-content-center align-center`)}>
                                                        {displayData}
                                                    </div>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};
export default CustomTable;
