import React, { useState, useEffect, useMemo } from 'react';
import Card from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import { useSelector } from 'react-redux';
import RealTimeLineChart from '@components/Charts/Components/RealTimeLineChart';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { convertState } from '@reducer/Common/processSocketData';

const defaultData = {
    Example1: [4977],
    Example2: [817, 935, 818],
    Example3: [],
};

const getCategory = data => {
    const category = {};
    for (let key in data) {
        category[key] = data[key].length;
    }
    return category;
};

const filterConfig = {};
const RealTimeItemMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const [categoryStatus, setCategoryStatus] = useState({});
    const { mode } = useSelector(state => state.ScreenInfo);
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const data = useMemo(() => {
        return mode === SCREEN_MODE_EDIT ? defaultData : categoryStatus;
    }, [mode, categoryStatus]);

    const [categoryList, setCategoryList] = useState(getCategory(data));

    useEffect(() => {
        setCategoryList(getCategory(data));
    }, [data]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig,
        handler: data => {
            const {
                target: { targetNum },
                targetCategory: { categoryCode: targetCategoryCode },
                targetState,
            } = data;
            const { lostSignal } = convertState(targetState);
            setCategoryStatus(prev => {
                const newCategoryStatus = {};
                for (const categoryCode in prev) {
                    newCategoryStatus[categoryCode] = prev[categoryCode].filter(exist => exist !== targetNum);
                }
                if (!lostSignal) {
                    if (newCategoryStatus[targetCategoryCode]) {
                        newCategoryStatus[targetCategoryCode].push(targetNum);
                    } else {
                        newCategoryStatus[targetCategoryCode] = [targetNum];
                    }
                }
                return newCategoryStatus;
            });
        },
    });

    // 신호가 끊겼을때 오류처럼 보이지 않게 설정
    useEffect(() => {
        const timer = setInterval(() => {
            setCategoryList({ ...categoryList });
        }, 2000);
        return () => clearInterval(timer);
    }, [categoryList]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <RealTimeLineChart data={categoryList} tagOption={Number(settings.tagOption)} dataInteger />
            {children}
        </Card>
    );
};

export default RealTimeItemMonitoring;
