import { getOutpatientWaitTime, postOutpatientWaitTimeExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useMethodOptions from '../util/useMethodOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDateTypeOptions from '../util/useDateTypeOptions';

// 외래 대기시간 분석 (시간대별)
const OutpatientWaitAnalysisByTime = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitTime,
        immediate: true,
        param: {
            departmentCode: departmentValues.department?.toString(),
            method: methodValues.method.value,
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightTotalAvgWaits = 0, insightMaxWaitAvg = 0, insightMaxDepartmentName = '' } = state.response
        ? state.response.additionalDatas
        : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitTimeExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'wait0800',
                    name: `${t('Wait Time')} (08:00) (${t('Minute')})`,
                },
                {
                    key: 'wait0830',
                    name: `${t('Wait Time')} (08:30) (${t('Minute')})`,
                },
                {
                    key: 'wait0900',
                    name: `${t('Wait Time')} (09:00) (${t('Minute')})`,
                },
                {
                    key: 'wait0930',
                    name: `${t('Wait Time')} (09:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1000',
                    name: `${t('Wait Time')} (10:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1030',
                    name: `${t('Wait Time')} (10:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1100',
                    name: `${t('Wait Time')} (11:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1130',
                    name: `${t('Wait Time')} (11:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1200',
                    name: `${t('Wait Time')} (12:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1230',
                    name: `${t('Wait Time')} (12:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1300',
                    name: `${t('Wait Time')} (13:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1330',
                    name: `${t('Wait Time')} (13:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1400',
                    name: `${t('Wait Time')} (14:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1430',
                    name: `${t('Wait Time')} (14:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1500',
                    name: `${t('Wait Time')} (15:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1530',
                    name: `${t('Wait Time')} (15:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1600',
                    name: `${t('Wait Time')} (16:00) (${t('Minute')})`,
                },
                {
                    key: 'wait1630',
                    name: `${t('Wait Time')} (16:30) (${t('Minute')})`,
                },
                {
                    key: 'wait1700',
                    name: `${t('Wait Time')} (17:00) (${t('Minute')})`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_time',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans
                                t={t}
                                values={{
                                    insightTotalAvgWaits,
                                    insightMaxWaitAvg,
                                    insightMaxDepartmentName,
                                }}
                                components={[<HighlightedText />]}
                            >
                                {
                                    'During the selected period, the average outpatient waiting time for the chosen departments is <0>{{insightTotalAvgWaits}} minutes</0>, with the longest average waiting time being <0>{{insightMaxWaitAvg}} minutes</0> for <0>{{insightMaxDepartmentName}}</0>'
                                }
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>

                    <Chart data={data} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitAnalysisByTime;
