import React, { Children, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultConfig, { monitoringConfig } from './config';
import { Responsive, WidthProvider } from 'react-grid-layout';
import FilterWidget from '../../FilterWidget';
import { setFilterList, setSelectedFilter, updateFilterInfo } from '@reducer/FilterInfo';
import { isEditing, SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import { useRouteMatch } from 'react-router-dom';
import { useAsync } from '@hooks';
import { getFilterListApi, getSelectedFilterApi } from '@api/common/filter';
import useTranslation from '@hooks/useTranslation';
import { A_PATH_CUSTOM_SCREEN, A_PATH_EMBED_CUSTOM_SCREEN, A_PATH_SCREEN_EDIT } from '../../Router/path';
import cx from 'classnames';
import { mergeObjects } from '@util/common/util';

const ResponsiveGridLayout = WidthProvider(Responsive);

const getNoFilterOption = t => ({ filterNum: 0, filterName: t('No filter') });

const ScreenFrame = ({ children, layouts, ...rest }) => {
    const t = useTranslation('Filter');
    const { params } = useRouteMatch([A_PATH_CUSTOM_SCREEN, A_PATH_SCREEN_EDIT, A_PATH_EMBED_CUSTOM_SCREEN]);
    const embedMatch = useRouteMatch(A_PATH_EMBED_CUSTOM_SCREEN);
    const { originScreenList } = useSelector(state => state.CustomMenu);
    const dispatch = useDispatch();
    const { enableClosedSidebar } = useSelector(state => state.ThemeOptions);
    const { filterInfo, filterList } = useSelector(state => state.FilterInfo);
    const { mode, fullScreen } = useSelector(state => state.ScreenInfo);
    const [modeConfig, setModeConfig] = useState({});

    const { promise: getFilterInfo } = useAsync({
        promise: getSelectedFilterApi,
        resolve: res => {
            dispatch(setSelectedFilter(res));
        },
        reject: err => {
            console.log(err);
        },
    });

    const { promise: getFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setFilterList([getNoFilterOption(t), ...res.rows]));
        },
        reject: err => {
            console.log(err);
            dispatch(setFilterList([getNoFilterOption(t)]));
        },
    });

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [enableClosedSidebar, fullScreen]);

    useEffect(() => {
        if (mode !== SCREEN_MODE_EDIT) {
            setModeConfig(monitoringConfig);
        } else {
            getFilterList();
            setModeConfig({});
        }
    }, [mode]);

    useEffect(() => {
        const { screenCode } = params;
        const screenInfo = originScreenList.find(screen => screen.screenCode === screenCode) || {};
        if (screenInfo.filterNum) {
            getFilterInfo({ filterNum: screenInfo.filterNum });
        } else {
            dispatch(setSelectedFilter());
        }
    }, [params.screenCode, originScreenList]);

    useEffect(() => {
        dispatch(setFilterList([getNoFilterOption(t), ...filterList.filter((filter, i) => i > 0)]));
    }, [t]);

    const heightOptimizationClassName = useMemo(() => {
        let className = '';
        const widgetCount = Children.count(children);
        if (widgetCount === 1) {
            Children.forEach(children, child => {
                const { config } = child.props.widgetInfo;
                if (config?.defaultSettings) {
                    const { heightOptimization } = mergeObjects(config.defaultSettings, config.settings);
                    if (heightOptimization) {
                        className = 'widget-fill-height';
                    }
                }
            });
        }
        return className;
    }, [children]);

    return (
        <>
            {!embedMatch && !fullScreen && (filterInfo.filterNum || mode === SCREEN_MODE_EDIT) && (
                <FilterWidget
                    filterInfo={filterInfo}
                    handleFilterConditionChange={selected => {
                        dispatch(
                            updateFilterInfo({
                                filterInfoCondition: filterInfo.filterInfoCondition.map(conditionInfo => {
                                    if (conditionInfo.conditionId === selected.conditionId) {
                                        return { ...conditionInfo, conditionValues: selected.conditionValues };
                                    }
                                    return conditionInfo;
                                }),
                            }),
                        );
                    }}
                />
            )}
            <div
                className={cx(
                    'app-page-content',
                    (embedMatch || fullScreen) && 'dashboard-fullscreen',
                    heightOptimizationClassName,
                )}
            >
                {/* ※지우지 마세요. 화면 레이아웃 설정을 위한 중간 컨테이너 */}
                <div className={'h-100'}>
                    <ResponsiveGridLayout
                        key={mode}
                        {...defaultConfig}
                        layouts={layouts}
                        {...rest}
                        {...modeConfig}
                        className={cx(!isEditing(mode) && 'h-100')}
                    >
                        {children}
                    </ResponsiveGridLayout>
                </div>
            </div>
        </>
    );
};

export default ScreenFrame;
