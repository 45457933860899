import React, { useState } from 'react';
import { Button, Select, TextInput } from '@components';
import { useTranslation } from '@util/hooks';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';

const SearchGroup = ({ initialFilter, options, onSubmit, onRefreshClick }) => {
    const t = useTranslation('OutpatientStorage');

    const [filterDraft, setFilterDraft] = useState(initialFilter);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(filterDraft);
    };

    const handleRefreshClick = () => {
        setFilterDraft(initialFilter);
        onRefreshClick();
    };

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup>
                <FilterList>
                    <Select
                        value={filterDraft.sort}
                        options={options}
                        onChange={selected => {
                            setFilterDraft({ ...filterDraft, sort: selected });
                            onSubmit({ ...filterDraft, sort: selected });
                        }}
                    />
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex">
                            <TextInput
                                value={filterDraft.keyword}
                                placeholder={t('Enter the patients name or patients ID.')}
                                handleChange={e => setFilterDraft({ ...filterDraft, keyword: e.target.value })}
                            />
                            <Button type="submit" className="btn-secondary">
                                {t('Search', 'Search')}
                            </Button>
                            <Button
                                className="btn-line btn-icon-only ml-2"
                                iconName="refresh"
                                onClick={handleRefreshClick}
                            />
                        </div>
                    </form>
                </FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default SearchGroup;
