import React, { useState } from 'react';

import { Table } from '@components';
import { useAppSelector, useAsync, useColumns, useTranslation } from '@hooks';
import { CATEGORY_CODE } from '@util/mappInfo';
import * as mainCols from '../../../MainPages/util/grid/column';

import WidgetCard from '../../Components/WidgetCard';
import Search, { DateRange } from './Components/Search';
import moment from 'moment';
import { WidgetProps } from '../../staticInfo';
import { UnixTimestamp } from '@util/type/util';
import { getEndOfDate, getStartOfDate } from '@util/date';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import ExcelExport from './Components/ExcelExport';
import { EmergencyAlertOpenLog, getEmergencyAlertOpenLogApi } from '@api/sh/biometricInformationMonitoring';

const initWardList = (groupNums?: number[]) => {
    return (groupNums || []).map(num => String(num));
};

const PatientAnomalyAlertHistory = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const userInfo: { groupNums?: number[] } = useAppSelector(state => state.UserInfo.userInfo);

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: getStartOfDate(),
        endDate: getEndOfDate(),
    });
    const [targetIdOrName, setTargetIdOrName] = useState('');
    const [selectedWardList, setSelectedWardList] = useState<string[]>(() => initWardList(userInfo.groupNums));

    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    // 알림 이력 조회
    const [history, setHistory] = useState<{ rows: EmergencyAlertOpenLog[] }>({ rows: [] });

    const { state, promise } = useAsync({
        fixedParam: {
            categoryCode: CATEGORY_CODE.PATIENT,
            pageSize: 50,
        },
        param: {
            categoryCode: CATEGORY_CODE.PATIENT,
            wardList: initWardList(userInfo.groupNums),
            ...dateRange,
        },
        promise: getEmergencyAlertOpenLogApi,
        resolve: res => {
            setHistory({
                ...res,
                rows: res.rows.map(log => {
                    return { ...log, regTime: log.regDate };
                }),
            });
        },
        immediate: true,
        deps: [userInfo.groupNums],
    });

    const columns = useColumns(
        [
            {
                Header: t('Date'),
                className: 'text-ellipsis',
                accessor: 'regDate',
                width: 90,
                Cell: ({ value }: { value: number }) => moment(value * 1000).format('YYYY-MM-DD'),
            },
            {
                Header: t('Ward'),
                accessor: 'wardName',
                width: 60,
            },
            {
                Header: t('Room'),
                accessor: 'room',
                width: 60,
            },
            {
                Header: t('Bed'),
                accessor: 'bed',
                width: 60,
            },
            mainCols.targetName({
                Header: t('Patient Name'),
                className: 'text-center',
                width: 90,
            }),
            mainCols.targetId({ Header: 'Patient Number', className: 'text-ellipsis', width: 70 }),
            {
                Header: t('Heartbeat', 'SensingType'),
                accessor: 'heartbeat',
                width: 60,
            },
            {
                Header: t('Respiration Rate', 'SensingType'),
                accessor: 'respirationRate',
                width: 60,
            },
            {
                Header: t('Body Temperature', 'SensingType'),
                accessor: 'temperature',
                width: 60,
            },
            {
                Header: t('Alert Popup Open Time'),
                className: 'text-ellipsis',
                accessor: 'regTime',
                width: 90,
                Cell: ({ value }: { value: number }) => moment(value * 1000).format('HH:mm:ss'),
            },
            {
                Header: t('Location In Alerts'),
                className: 'text-ellipsis',
                accessor: 'fcNameList',
                width: 120,
                Cell: ({ value, row: { original } }: { value: string[]; row: { original: EmergencyAlertOpenLog } }) =>
                    value?.length
                        ? value
                              .map(fcName => (original.floorName ? original.floorName + ' ' + fcName : fcName))
                              .join(', ')
                        : original.floorName ?? '',
            },
        ],
        t,
        [wardMapInfo],
    );

    const handlePageChange = (pageIndex: number) => {
        const param = state.request;
        promise({ ...param, page: pageIndex });
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                ![SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW].includes(mode ?? '') && (
                    <ExcelExport param={state.request ?? {}} />
                )
            }
            searchFilter={
                <Search
                    dateRange={dateRange}
                    handleDateRange={setDateRange}
                    targetIdOrName={targetIdOrName}
                    handleTargetIdOrName={setTargetIdOrName}
                    selectedWardList={selectedWardList}
                    handleSelectedWardList={setSelectedWardList}
                    handleReset={() => {
                        const initialDate = {
                            startDate: getStartOfDate(),
                            endDate: getEndOfDate(),
                        };
                        const initialWardList = initWardList(userInfo.groupNums);
                        setDateRange(initialDate);
                        setTargetIdOrName('');
                        setSelectedWardList(initialWardList);
                        promise({ ...initialDate, categoryCode: CATEGORY_CODE.PATIENT, wardList: initialWardList });
                    }}
                    handleSearchClick={() => {
                        const param: {
                            startDate: UnixTimestamp;
                            endDate: UnixTimestamp;
                            targetIdOrName?: string;
                            wardList?: string[];
                        } = {
                            ...dateRange,
                        };
                        if (targetIdOrName.trim()) {
                            param.targetIdOrName = targetIdOrName.trim();
                        }
                        if (selectedWardList.length) {
                            param.wardList = selectedWardList;
                        }
                        promise({ ...param, categoryCode: CATEGORY_CODE.PATIENT });
                    }}
                />
            }
            bodyClassName={'flx-col h-100 gap-0'}
            {...restProps}
        >
            <Table
                columns={columns}
                data={{ ...history, pageSize: 50 }}
                onPageChange={handlePageChange}
                noDataMessage={t('No patient anomaly alert history.')}
                loading={state.isLoading}
            />
            {children}
        </WidgetCard>
    );
};

export default PatientAnomalyAlertHistory;
