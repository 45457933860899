import React, { useState } from 'react';
import { Button, DatePicker } from '@components';
import moment from 'moment';
import styles from '@asset/pnt/widgets/so/WeekDatePickerGroupSO.module.scss';
import { useAppSelector } from '@hooks';

const DatePickerGroupSO = ({
    dateType = 'daily', // daily, weekly, monthly, yearly
    startDate,
    handleStartChange,
    handleStartLeftClick,
    handleStartRightClick,
    endDate,
    handleEndChange,
    handleEndLeftClick,
    handleEndRightClick,
}) => {
    const { lang } = useAppSelector(state => state.UserInfo);
    const startDateMs = startDate * 1000;
    const [isStartDateMonthSelect, setIsStartDateMonthSelect] = useState(false);
    const [isEndDateMonthSelect, setIsEndDateMonthSelect] = useState(false);

    const handleStartMonthChange = () => {
        setIsStartDateMonthSelect(false); // 달 선택 후 날짜 선택 모드로 돌아가기
    };

    const handleStartDateChange = date => {
        handleStartChange(date);
    };

    const handleEndMonthChange = () => {
        setIsEndDateMonthSelect(false); // 달 선택 후 날짜 선택 모드로 돌아가기
    };

    const handleEndDateChange = date => {
        handleEndChange(date);
    };

    return (
        <>
            {dateType === 'daily' && (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={isStartDateMonthSelect ? handleStartMonthChange : handleStartDateChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                        showMonthYearPicker={isStartDateMonthSelect} // 월 선택 모드
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <CustomHeader
                                date={date}
                                selectMode={isStartDateMonthSelect}
                                setSelectMode={setIsStartDateMonthSelect}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                            />
                        )}
                        shouldCloseOnSelect={!isStartDateMonthSelect} // 기본적으로 선택 후 닫히지 않도록 설정
                        onCalendarClose={() => setIsStartDateMonthSelect(false)} // 달력이 닫힐 때 상태 초기화
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={isEndDateMonthSelect ? handleEndMonthChange : handleEndDateChange}
                        withoutTime
                        valueType="s"
                        minDate={startDateMs}
                        disabledKeyboardNavigation
                        showMonthYearPicker={isEndDateMonthSelect} // 월 선택 모드
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <CustomHeader
                                date={date}
                                selectMode={isEndDateMonthSelect}
                                setSelectMode={setIsEndDateMonthSelect}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                            />
                        )}
                        shouldCloseOnSelect={!isEndDateMonthSelect} // 기본적으로 선택 후 닫히지 않도록 설정
                        onCalendarClose={() => setIsEndDateMonthSelect(false)} // 달력이 닫힐 때 상태 초기화
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('day').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            )}
            {dateType === 'weekly' && (
                <>
                    <DatePicker
                        popperClassName={`${styles.weekCalendar} datepicker-popper-display`}
                        calendarClassName={'week-' + lang}
                        value={startDate}
                        onChange={isStartDateMonthSelect ? handleStartMonthChange : handleStartDateChange}
                        withoutTime
                        valueType="s"
                        showMonthYearPicker={isStartDateMonthSelect} // 월 선택 모드
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <CustomHeader
                                date={date}
                                selectMode={isStartDateMonthSelect}
                                setSelectMode={setIsStartDateMonthSelect}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                            />
                        )}
                        shouldCloseOnSelect={!isStartDateMonthSelect} // 기본적으로 선택 후 닫히지 않도록 설정
                        onCalendarClose={() => setIsStartDateMonthSelect(false)} // 달력이 닫힐 때 상태 초기화
                        dateFormat="yyyy-MM-dd"
                        // 주 선택 시
                        onWeekSelect={week => handleStartDateChange(week)}
                        // 날짜에 className
                        dayClassName={() => 'control-disabled'}
                        showWeekNumbers
                        showWeekPicker
                        disabledKeyboardNavigation
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        popperClassName={`${styles.weekCalendar} datepicker-popper-display`}
                        calendarClassName={'week-' + lang}
                        value={endDate}
                        onChange={isEndDateMonthSelect ? handleEndMonthChange : handleEndDateChange}
                        withoutTime
                        valueType="s"
                        minDate={startDateMs}
                        showMonthYearPicker={isEndDateMonthSelect} // 월 선택 모드
                        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <CustomHeader
                                date={date}
                                selectMode={isEndDateMonthSelect}
                                setSelectMode={setIsEndDateMonthSelect}
                                decreaseMonth={decreaseMonth}
                                increaseMonth={increaseMonth}
                            />
                        )}
                        shouldCloseOnSelect={!isEndDateMonthSelect} // 기본적으로 선택 후 닫히지 않도록 설정
                        onCalendarClose={() => setIsEndDateMonthSelect(false)} // 달력이 닫힐 때 상태 초기화
                        dateFormat="yyyy-MM-dd"
                        onWeekSelect={week => handleEndDateChange(week)}
                        dayClassName={() => 'control-disabled'}
                        disabledKeyboardNavigation
                        showWeekNumbers
                        showWeekPicker
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('week').add(1, 'days').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            )}
            {dateType === 'monthly' && (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                        minDate={startDateMs}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('month').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            )}
            {dateType === 'yearly' && (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showYearPicker
                        dateFormat="yyyy"
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showYearPicker
                        dateFormat="yyyy"
                        minDate={startDateMs}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('year').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            )}
        </>
    );
};

export default DatePickerGroupSO;

const CustomHeader = ({ date, selectMode, setSelectMode, decreaseMonth, increaseMonth }) => {
    const { lang } = useAppSelector(state => state.UserInfo);
    return (
        <div className={selectMode ? 'pb-2' : ''}>
            <button
                onClick={decreaseMonth}
                className={'react-datepicker__navigation react-datepicker__navigation--previous'}
            >
                {'<'}
            </button>
            <span
                onClick={() => setSelectMode(true)} // 클릭 시 달 선택 모드로 전환
                className={'pnt-txt txt-bold s-8 cursor-pointer'}
            >
                {moment(date).locale(lang).format('MMMM YYYY')}
                {/* 달과 연도 표시 */}
            </span>
            <button
                onClick={increaseMonth}
                className={'react-datepicker__navigation react-datepicker__navigation--next'}
            >
                {'>'}
            </button>
        </div>
    );
};
