import { useAsync } from '@util/hooks';
import { useState } from 'react';
import { Filter } from '..';
import { getOutstandingAmountList } from '@api/so/outpatient';
import { UnixTimestamp } from '@util/type/util';

export interface Row {
    patientId: string;
    patientName: string;
    phoneNum: string;
    lastSendingDate: UnixTimestamp;
    amount: string;
    historyCount: number;
}

interface Response {
    page: number;
    totalCount: number;
    totalPage: number;
    rows: Row[];
}

interface Param {
    keyword: string;
    sortOption: string;
    page: number;
    pageSize: number;
}

export interface Data extends Response {
    pageSize: number;
}

export const useAmountList = (filter: Filter, defaultOptionValue: string) => {
    const [data, setData] = useState<Data>();

    const { promise: getList } = useAsync<Param, Response>({
        promise: getOutstandingAmountList,
        immediate: true,
        param: {
            keyword: filter.keyword,
            sortOption: filter.sort ? filter.sort.value : defaultOptionValue,
            page: filter.page,
            pageSize: 15,
        },
        deps: [filter],
        resolve: (response, request) => {
            const { pageSize } = request;
            setData({ ...response, pageSize });
        },
        reject: err => console.error(err),
    });

    return { data, setData, getList };
};
