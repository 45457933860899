import React, { useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../util/useSettings';
import { useSelector } from 'react-redux';
import WidgetCard from '../../Components/WidgetCard';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import FloorPermissionChart from './Components/FloorPermissionChart';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { checkLostSignal, getPermittedState, isIn } from '@util/mappInfo';

const initData = [
    {
        key: 'PNT',
        name: 'PNT',
        authorized: 12,
        unAuthorized: 2,
    },
    {
        key: 'ExampleFloor1',
        name: 'ExampleFloor1',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
    {
        key: 'ExampleFloor2',
        name: 'ExampleFloor2',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
    {
        key: 'ExampleFloor3',
        name: 'ExampleFloor3',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
];

const FloorPermissionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('FloorPermissionStatus');
    const settings = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const [floorPermissionStatus, setFloorPermissionStatus] = useState(initData);
    const [floorInOutState, setFloorInOutState] = useState({});

    const chartMeta = {
        dataSet: [
            { key: 'authorized', name: t('Authorized'), color: '#1f77b4' },
            { key: 'unAuthorized', name: t('Unauthorized'), color: '#ff7f0e' },
        ],
    };
    //
    const socketFilterConfig = useMemo(() => [], []);

    const getFloorInOutState = compact => {
        setFloorInOutState(prev => {
            const floorInfo = { ...prev };
            compact.forEach(v => {
                const { isIn, permitted, target, lostSignal, floorId } = v;
                if (!lostSignal && isIn) {
                    if (!floorInfo[floorId]) {
                        floorInfo[floorId] = { authorized: [], unAuthorized: [] };
                    }

                    const otherKey = permitted === 'authorized' ? 'unAuthorized' : 'authorized';

                    if (!floorInfo[floorId][permitted].includes(target)) {
                        floorInfo[floorId][permitted].push(target);
                    }

                    if (floorInfo[floorId][otherKey].includes(target)) {
                        floorInfo[floorId][otherKey] = floorInfo[floorId][otherKey].filter(v => v !== target);
                    }
                }
                if (lostSignal || !isIn) {
                    if (floorInfo[floorId]) {
                        floorInfo[floorId][permitted] = floorInfo[floorId][permitted].filter(v => v !== target);
                    }
                }
            });
            return floorInfo;
        });
    };

    useSocketEvent({
        filterConfig: socketFilterConfig,
        name: EVENT_TYPE_LOCATION,
        handler: data => {
            const compact = [];
            if (data) {
                data.forEach(v => {
                    compact.push({
                        floorId: v.currentFloor?.floorId,
                        floorName: v.currentFloor?.floorName,
                        targetName: v.target.targetName,
                        isIn: isIn(v.currentFloor?.inOutState),
                        inTime: v.currentFloor?.inUnixTime,
                        permitted: getPermittedState(v?.currentFloor?.permitted),
                        target: v.target.targetNum,
                        lostSignal: checkLostSignal(v.targetState.lostSignal),
                    });
                });
            }

            getFloorInOutState(compact);
        },
        enableBuffer: true,
    });

    useEffect(() => {
        setFloorPermissionStatus(
            floorList.map(v => {
                return {
                    key: v.floorId,
                    name: v.floorName,
                    authorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].authorized.length
                            : 0,
                    unAuthorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].unAuthorized.length
                            : 0,
                };
            }),
        );
    }, [floorList, floorInOutState]);

    const data = useMemo(() => {
        return mode === SCREEN_MODE_EDIT ? initData : floorPermissionStatus;
    }, [mode, floorPermissionStatus]);

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <FloorPermissionChart data={data} chartMeta={chartMeta} />
                </PerfectScrollbar>
            </div>
            {children}
        </WidgetCard>
    );
};

export default FloorPermissionStatus;
