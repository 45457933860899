import React from 'react';
import { Button, SearchableSelect, Select } from '@components';
import { useTranslation } from '@hooks';
import { OUTPATIENT_REPORT } from '../../constants';
import DatePickerGroupSO from './DatePickerGroupSO';
import DateTypeGroup from './DateTypeGroup';

const SearchGroupSO = ({
    // 일별, 주별, 월별, 년별 Selected value
    showDateType,
    dateTypeValues,
    // 부서 Select
    showDepartment,
    departmentValues,
    // 방식(개별, 합산) Select
    showMethod,
    methodValues,
    // 기간 DatePicker
    dateValues,
    // 조회 버튼 클릭
    getData,
    // 다운로드 버튼 유무
    showDownload,
    handleDownload,
}) => {
    const { dateType, handleDateTypeChange } = dateTypeValues || {};
    const { departmentOptions, department, handleDepartmentChange } = departmentValues || {};
    const { methodOptions, method, handleMethodChange } = methodValues || {};
    const {
        startDate,
        handleStartChange,
        handleStartLeftClick,
        handleStartRightClick,
        endDate,
        handleEndChange,
        handleEndLeftClick,
        handleEndRightClick,
    } = dateValues || {};
    const t = useTranslation(OUTPATIENT_REPORT);

    const handleInquiryClick = () => {
        getData();
    };

    return (
        <div className={'d-flex justify-content-between'}>
            <div className="d-flex gap-3">
                {showDepartment && (
                    <SearchableSelect
                        selected={department}
                        onChange={handleDepartmentChange}
                        data={departmentOptions}
                    />
                )}
                {showMethod && <Select value={method} onChange={handleMethodChange} options={methodOptions} />}
                {showDateType && <DateTypeGroup selected={dateType} handleDateTypeChange={handleDateTypeChange} />}
                <div className="d-flex gap-2 align-items-center">
                    <DatePickerGroupSO
                        dateType={dateType}
                        startDate={startDate}
                        handleStartChange={handleStartChange}
                        handleStartLeftClick={handleStartLeftClick}
                        handleStartRightClick={handleStartRightClick}
                        endDate={endDate}
                        handleEndChange={handleEndChange}
                        handleEndLeftClick={handleEndLeftClick}
                        handleEndRightClick={handleEndRightClick}
                    />
                </div>
                <Button className="btn-secondary" onClick={handleInquiryClick}>
                    {t('inquiry')}
                </Button>
            </div>
            {showDownload && (
                <Button className={'btn-secondary'} onClick={handleDownload}>
                    {t('Download')}
                </Button>
            )}
        </div>
    );
};

export default SearchGroupSO;
