import React from 'react';
import { Modal } from '@components';
import { ModalBody } from 'reactstrap';

const ImageModal = ({ initModal, toggleModal, imgSrc }) => {
    return (
        <Modal initModal={initModal} toggleModal={toggleModal} removeCancel>
            <ModalBody>
                <img className={'w-100 h-100'} alt={'image'} src={imgSrc} />
            </ModalBody>
        </Modal>
    );
};

export default ImageModal;
