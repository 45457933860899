import { getOutpatientUsageDay, postOutpatientUsageDayExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useMethodOptions from '../util/useMethodOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDateTypeOptions from '../util/useDateTypeOptions';

// 외래 사용량 분석(요일별)
const OutpatientUsageAnalysisByDay = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDay,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightWeekdays } = state.response ? state.response.additionalDatas : {};
    const [originDay1, originDay2] = Array.isArray(insightWeekdays) ? insightWeekdays : [];
    const day1 = t(originDay1);
    const selectDepartmentName = data.length > 0 ? data[0].departmentName : '';

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageDayExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            columnMetas: [
                {
                    key: 'regDay',
                    name: t('Date(day)'),
                },
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'kioskCountMon',
                    name: `${t('Usage (Mon)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateMon',
                    name: `${t('Usage Rate(Mon)')} (%)`,
                },
                {
                    key: 'kioskCountTue',
                    name: `${t('Usage (Tue)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateTue',
                    name: `${t('Usage Rate(Tue)')} (%)`,
                },
                {
                    key: 'kioskCountWed',
                    name: `${t('Usage (Wed)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateWed',
                    name: `${t('Usage Rate(Wed)')} (%)`,
                },
                {
                    key: 'kioskCountThu',
                    name: `${t('Usage (Thu)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateThu',
                    name: `${t('Usage Rate(Thu)')} (%)`,
                },
                {
                    key: 'kioskCountFri',
                    name: `${t('Usage (Fri)')} (${t('Cases')})`,
                },
                {
                    key: 'kioskRateFri',
                    name: `${t('Usage Rate(Fri)')} (%)`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_day',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans t={t} values={{ day1, selectDepartmentName }} components={[<HighlightedText />]}>
                                {data.length >= 2
                                    ? 'During the selected period, the day with the highest kiosk usage for the chosen departments is <0>{{day1}}</0>.'
                                    : 'During the selected period, the day with the highest kiosk usage for <0>{{selectDepartmentName}}</0> is <0>{{day1}}</0>.'}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDay;
