import { useState } from 'react';

const useDateTypeOptions = (initValue = 'monthly') => {
    // 초기값은 월별로
    const [dateType, setDateType] = useState(initValue);

    const handleDateTypeChange = e => {
        setDateType(e.target.value);
    };

    return {
        dateType,
        handleDateTypeChange,
    };
};

export default useDateTypeOptions;
