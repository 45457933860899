import React, { useState } from 'react';
import { Button, ConfirmModal } from '@components';
import { useTranslation } from '@util/hooks';
import ExampleModal from './ExampleModal';
import useModal from '../../../util/useModal';
import { useAsync, useConfirm } from '@hooks';
import { postOutstandingAmountNotification } from '@api/so/outpatient';

interface Props {
    checkedList: number[];
    disabled: boolean;
    refresh: () => void;
}

const SendContainer = ({ checkedList, disabled, refresh }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [isOpen, handleToggle, handleOpen] = useModal();
    const [alertText, setAlertText] = useState('');

    const handleSendClick = () => {
        promise({ unpaidAmountNums: checkedList });
    };

    const handleInfoClick = () => {
        handleOpen();
    };

    const alertModalProps = useConfirm({
        initModal: false,
        confirmText: alertText,
        removeCancel: true,
        okCallback: () => {
            refresh();
        },
    });

    const { promise } = useAsync({
        promise: postOutstandingAmountNotification,
        resolve: () => {
            // 전송 성공 시
            setAlertText(`${checkedList.length} ${t('AlimTalk notifications have been successfully sent.')}`);
            alertModalProps.toggleModal();
        },
        reject: () => {
            // 전송 실패 시
            setAlertText(t('AlimTalk failed to send.'));
            alertModalProps.toggleModal();
        },
    });

    return (
        <>
            <div className="d-flex align-items-center">
                <Button className="btn-secondary" onClick={handleSendClick} disabled={disabled}>
                    {t('Send Outstanding Payment Notification')}
                </Button>
                <Button className="btn-icon-only bg-transparent" iconName="info" onClick={handleInfoClick} />
            </div>
            {isOpen && <ExampleModal isOpen={isOpen} toggleModal={handleToggle} />}
            <ConfirmModal {...alertModalProps} />
        </>
    );
};

export default SendContainer;
