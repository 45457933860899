import React from 'react';
import { IconProps } from '@components/type';
import cx from 'classnames';

const Icon = ({ className, style, iconName }: IconProps) => {
    return (
        <span className={cx('material-icons-round', className)} style={style}>
            {iconName}
        </span>
    );
};

export default Icon;
