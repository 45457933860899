import React, { useRef, useState } from 'react';
import { OptionType } from '@components/type';
import { useTranslation } from '@util/hooks';
import { WidgetProps } from '../../staticInfo';
import OutstandingAmountList from './Components/OutstandingAmountList';
import SearchGroup from './Components/SearchGroup';
import { useAmountList } from './hooks/useAmountList';
import WidgetCard from '../../Components/WidgetCard';

export interface Filter {
    sort: OptionType | null;
    keyword: string;
    page: number;
}

const DEFAULT_DATA = {
    page: 0,
    totalCount: 0,
    totalPage: 0,
    pageSize: 0,
    rows: [],
};

const OutstandingAmountNotification = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('OutpatientStorage');
    const options = [
        { label: t('Latest send date'), value: 'latest' },
        { label: t('Earliest send date'), value: 'earliest' },
        { label: t('Total amount desc.'), value: 'desc' },
        { label: t('Total amount asc.'), value: 'asc' },
    ];
    const initialFilter = {
        sort: options[0],
        keyword: '',
        page: 1,
    };

    const [filter, setFilter] = useState<Filter>(initialFilter);
    const { data, getList } = useAmountList(filter, options[0].value);

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <div className="d-flex flex-column gap-3">
                <SearchGroup
                    initialFilter={initialFilter}
                    options={options}
                    onSubmit={(filter: Filter) => {
                        setFilter(filter);
                    }}
                    onRefreshClick={() => {
                        setFilter(initialFilter);
                    }}
                />
                <OutstandingAmountList
                    data={data ?? DEFAULT_DATA}
                    onPageChange={(page: number) => {
                        setFilter({ ...filter, page });
                    }}
                    refreshList={getList}
                />
            </div>
            {children}
        </WidgetCard>
    );
};

export default OutstandingAmountNotification;
