import React from 'react';
import { Button, Modal } from '@components';
import { useTranslation } from '@util/hooks';
import imgSrc from '@asset/images/notification_image.webp';
import { ModalFooter } from 'reactstrap';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
}

const ExampleModal = ({ isOpen, toggleModal }: Props) => {
    const t = useTranslation('OutpatientStorage');

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            headerTitle={t('Outstanding Amount Notification Example')}
            bodyText={<img alt="" src={imgSrc} width="100%" />}
            removeCancel={true}
            modalFooter={
                <ModalFooter className={'d-flex justify-content-center'}>
                    <Button onClick={toggleModal} className={'color-white'}>
                        {t('Close', 'Button')}
                    </Button>
                </ModalFooter>
            }
        />
    );
};

export default ExampleModal;
