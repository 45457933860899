import React from 'react';
import { Button, Modal, Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import { ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { getNotificationHistoryList } from '@api/so/outpatient';
import { formatDateByColumn } from '../../../util/format';
import { DetailRow } from './OutstandingDetail';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    unpaidNum: number | null;
}

const OutstandingHistory = ({ isOpen, toggleModal, unpaidNum }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const columns = useColumns([
        {
            Header: t('Sent date'),
            accessor: 'sendingDate',
            Cell: ({ row }: { row: DetailRow }) => {
                return <span>{formatDateByColumn(row, 'sendingDate') || '-'}</span>;
            },
        },
    ]);

    const { state, promise } = useAsync({
        promise: getNotificationHistoryList,
        fixedParam: { unpaidAmountNum: unpaidNum, pageSize: 5 },
        immediate: true,
        keepState: true,
    });

    const data = state?.response ?? { rows: [] };

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            headerTitle={t('Notification Send History')}
            removeCancel={true}
            removeModalClose={false}
            modalFooter={<></>}
        >
            <ModalBody>
                <TableWrapper>
                    <Table
                        columns={columns}
                        data={{ ...data, pageSize: 5 }}
                        onPageChange={page => {
                            promise({ page });
                        }}
                    />
                </TableWrapper>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-center align-items-center'}>
                <Button className={'btn-secondary'} onClick={toggleModal}>
                    {t('Confirm')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OutstandingHistory;

const TableWrapper = styled.div`
    height: 240px;
`;
