import { useState } from 'react';
import moment from 'moment';

export const formatStartUnixTime = (unixTime, dateType = 'daily') => {
    if (dateType === 'daily') {
        return moment.unix(unixTime).startOf('day').unix();
    }
    if (dateType === 'weekly') {
        return moment.unix(unixTime).startOf('week').add(1, 'days').unix(); // startOf week는 일요일
    }
    if (dateType === 'monthly') {
        return moment.unix(unixTime).startOf('month').unix();
    }
    if (dateType === 'yearly') {
        return moment.unix(unixTime).startOf('year').unix();
    }

    return unixTime;
};

export const formatEndUnixTime = (unixTime, dateType = 'daily') => {
    if (dateType === 'daily') {
        return moment.unix(unixTime).endOf('day').unix();
    }
    if (dateType === 'weekly') {
        // 당일 기준 주에 포함되어 있는지 확인
        const startDate = moment.unix(moment().unix()).startOf('week').add(1, 'days').unix();
        const endDate = moment.unix(moment().unix()).endOf('week').subtract(1, 'days').unix();

        const isCurrentWeek = startDate < unixTime && unixTime < endDate;

        return isCurrentWeek
            ? moment().endOf('day').unix()
            : moment.unix(unixTime).endOf('week').subtract(1, 'days').unix();
    }
    if (dateType === 'monthly') {
        return moment.unix(unixTime).endOf('month').unix();
    }
    if (dateType === 'yearly') {
        return moment.unix(unixTime).endOf('year').unix();
    }

    return unixTime;
};

const useRangeDateSO = (dateType = 'monthly') => {
    // 시작일은 반드시 해당 달의 1일
    const [startDate, setStartDate] = useState(moment().startOf('month').unix());
    const [endDate, setEndDate] = useState(moment().unix());

    const handleStartChange = date => {
        const unixTime = moment(date).unix();
        // 시작 날짜를 끝 날짜보다 크게 바꿀 때 동일하게 세팅
        setStartDate(unixTime);

        if (unixTime > endDate) {
            setEndDate(unixTime);
            const selectedDate = unixTime;
            const startDate = moment.unix(moment().unix()).startOf('week').unix();

            // 선택한 주가 현재 날짜의 주일때
            if (selectedDate === startDate) {
                setEndDate(moment().endOf('day').unix());
            }
        }
    };

    const handleStartLeftClick = () => {
        if (dateType === 'daily') {
            setStartDate(moment.unix(startDate).subtract(1, 'days').unix());
        }
        if (dateType === 'weekly') {
            setStartDate(moment.unix(startDate).subtract(1, 'weeks').unix());
        }
        if (dateType === 'monthly') {
            setStartDate(moment.unix(startDate).subtract(1, 'months').unix());
        }
        if (dateType === 'yearly') {
            setStartDate(moment.unix(startDate).subtract(1, 'years').unix());
        }
    };

    const handleStartRightClick = () => {
        let nextStartDate = startDate;
        if (dateType === 'daily') {
            nextStartDate = moment.unix(startDate).add(1, 'days').unix();
        }
        if (dateType === 'weekly') {
            nextStartDate = moment.unix(startDate).add(1, 'weeks').unix();
        }
        if (dateType === 'monthly') {
            nextStartDate = moment.unix(startDate).add(1, 'months').unix();
        }
        if (dateType === 'yearly') {
            nextStartDate = moment.unix(startDate).add(1, 'years').unix();
        }
        setStartDate(nextStartDate);
        if (nextStartDate > endDate) {
            setEndDate(nextStartDate);
        }
    };

    const handleEndChange = date => {
        const unixTime = moment(date).unix();
        setEndDate(unixTime);

        if (dateType === 'weekly') {
            const selectedDate = unixTime;
            const startDate = moment.unix(moment().unix()).startOf('week').unix();

            // 선택한 주가 현재 날짜의 주일때
            if (selectedDate === startDate) {
                setEndDate(moment().endOf('day').unix());
            }
        }
    };

    const handleEndLeftClick = () => {
        if (dateType === 'daily') {
            setEndDate(moment.unix(endDate).subtract(1, 'days').unix());
        }
        if (dateType === 'weekly') {
            setEndDate(moment.unix(endDate).subtract(1, 'weeks').unix());
        }
        if (dateType === 'monthly') {
            setEndDate(moment.unix(endDate).subtract(1, 'months').unix());
        }
        if (dateType === 'yearly') {
            setEndDate(moment.unix(endDate).subtract(1, 'years').unix());
        }
    };

    const handleEndRightClick = () => {
        if (dateType === 'daily') {
            setEndDate(moment.unix(endDate).add(1, 'days').unix());
        }
        if (dateType === 'weekly') {
            setEndDate(moment.unix(endDate).add(1, 'weeks').unix());
        }
        if (dateType === 'monthly') {
            setEndDate(moment.unix(endDate).add(1, 'months').unix());
        }
        if (dateType === 'yearly') {
            setEndDate(moment.unix(endDate).add(1, 'years').unix());
        }
    };

    return {
        startDate: formatStartUnixTime(startDate, dateType),
        handleStartChange,
        handleStartLeftClick,
        handleStartRightClick,
        endDate: formatEndUnixTime(endDate, dateType),
        handleEndChange,
        handleEndLeftClick,
        handleEndRightClick,
    };
};

export default useRangeDateSO;
