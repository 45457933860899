import React, { ChangeEvent, FormEvent } from 'react';
import { Button, DatePicker, TextInput } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import cx from 'classnames';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import moment from 'moment';
import { UnixTimestamp } from '@util/type/util';
import styled from 'styled-components';
import SearchableSelect from '@components/Select/SearchableSelect';

export type DateRange = { startDate: UnixTimestamp; endDate: UnixTimestamp };

type SearchProps = {
    handleSearchClick(): void;
    handleReset(): void;
    handleTargetIdOrName(targetIdOrName: string): void;
    targetIdOrName: string;
    handleDateRange({ startDate, endDate }: DateRange): void;
    dateRange: DateRange;
    handleSelectedWardList(wardList: string[]): void;
    selectedWardList: string[];
};

const Search = ({
    handleSearchClick,
    handleReset,
    handleTargetIdOrName,
    targetIdOrName,
    handleDateRange,
    dateRange,
    handleSelectedWardList,
    selectedWardList,
}: SearchProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const handleStartChange = (date: UnixTimestamp) => {
        handleDateRange({ ...dateRange, startDate: date });
    };
    const handleEndChange = (date: UnixTimestamp) => {
        handleDateRange({
            ...dateRange,
            endDate: moment(date * 1000)
                .endOf('day')
                .unix(),
        });
    };
    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        handleTargetIdOrName(value);
    };

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearchClick();
    };

    const handleRefresh = () => {
        handleReset();
    };

    return (
        <form className="flx-row" onSubmit={handleSearch}>
            <FilterSearchGroup className={cx('absolute-filter', 'w-100')}>
                <FilterGroup className={''} label={''}>
                    <FilterList className={''}>
                        <SearchableSelect
                            data={wardList}
                            labelKey={'wardName'}
                            valueKey={'wardNum'}
                            selected={selectedWardList}
                            onChange={selected => {
                                handleSelectedWardList(
                                    (selected ?? []).map((ward: { wardNum: string }) => ward.wardNum),
                                );
                            }}
                        />
                        <DatePicker
                            value={dateRange.startDate}
                            handleChange={handleStartChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                            minDate={moment().subtract(14, 'days').valueOf()}
                            maxDate={moment.unix(dateRange.endDate).valueOf()}
                        />
                        <span>~</span>
                        <DatePicker
                            value={dateRange.endDate}
                            handleChange={handleEndChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                            minDate={moment.unix(dateRange.startDate).valueOf()}
                            maxDate={moment().valueOf()}
                        />
                        <InputGroupWrapper>
                            <TextInput
                                inputGroupClassName={'w-100'}
                                type="text"
                                maxlength={13}
                                name="targetIdOrName"
                                value={targetIdOrName}
                                handleChange={handleSearchInput}
                                placeholder={t("Enter the patient's name or patient registration number.")}
                            />
                        </InputGroupWrapper>
                        <Button type="submit" className="btn-secondary">
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
                    </FilterList>
                </FilterGroup>
            </FilterSearchGroup>
        </form>
    );
};

const InputGroupWrapper = styled.div`
    @media screen and (min-width: 45rem) {
        width: 18rem;
    }
`;

export default Search;
