import React, { ChangeEvent, useMemo, useState } from 'react';
import { useAsync, useTranslation } from '@util/hooks';
import { Modal, Table } from '@components';
import styled from 'styled-components';
import { Row } from '../hooks/useAmountList';
import { getOutstandingAmountDetail } from '@api/so/outpatient';
import { ModalBody } from 'reactstrap';
import SendContainer from './SendContainer';
import OutstandingHistory from './OutstandingHistory';
import { formatPhoneNumber } from '../../../util/format';
import { NullableUnixTimestamp, UnixTimestamp } from '@util/type/util';
import moment from 'moment';
import { DATE_FORMAT } from '@util/times';
import { UnpaidType } from '../../NotificationHistory';

export interface DetailRow {
    unpaidAmountNum: number;
    patientId: string;
    departmentName: string;
    appointmentDate: UnixTimestamp;
    careEndDate: UnixTimestamp;
    amount: string;
    historyCount: number;
}

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    patientInfo: Row;
    refreshList: () => void;
}

const PAGE_SIZE = 10;

const OutstandingDetail = ({ isOpen, toggleModal, patientInfo, refreshList }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [historyOpen, setHistoryOpen] = useState(false);
    const [checkedList, setCheckedList] = useState<number[]>([]);
    const [unpaidNum, setUnpaidNum] = useState<number | null>(null);

    const { state, promise } = useAsync({
        promise: getOutstandingAmountDetail,
        fixedParam: { patientId: patientInfo.patientId, pageSize: PAGE_SIZE, page: 1 },
        immediate: true,
        keepState: true,
        resolve: () => {
            setCheckedList([]);
        },
        reject: err => console.error(err),
    });

    const rows = state?.response ?? { rows: [] };

    // 전체 선택 체크 여부: 모든 개별 체크박스가 체크되었는지 확인
    const isAllChecked =
        rows.rows.length > 0 &&
        rows.rows.every(({ unpaidAmountNum }: { unpaidAmountNum: number }) => checkedList.includes(unpaidAmountNum));

    // 전체 선택/해제 로직
    const handleAllCheck = (e: ChangeEvent<HTMLInputElement>) => {
        if (isAllChecked && !e.target.checked) {
            // 모든 체크박스를 해제
            setCheckedList([]);
        } else {
            // 모든 체크박스를 선택
            const newCheckedList = rows.rows.map(({ unpaidAmountNum }: { unpaidAmountNum: number }) => unpaidAmountNum);

            setCheckedList(newCheckedList);
        }
    };

    // 개별 선택/해제 로직
    const handleCheck = (unpaidAmountNum: number) => {
        setCheckedList(
            prevCheckedList =>
                prevCheckedList.includes(unpaidAmountNum)
                    ? prevCheckedList.filter(id => id !== unpaidAmountNum) // 선택 해제
                    : [...prevCheckedList, unpaidAmountNum], // 선택 추가
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: () => {
                    return <input type="checkbox" className="m-0" checked={isAllChecked} onChange={handleAllCheck} />;
                },
                width: 40,
                Cell: ({ row: { original } }: { row: { original: DetailRow } }) => {
                    const { unpaidAmountNum, patientId } = original;
                    return (
                        <div>
                            <input
                                name={patientId}
                                type="checkbox"
                                className="m-0"
                                checked={checkedList.includes(unpaidAmountNum)}
                                onChange={() => handleCheck(unpaidAmountNum)}
                            />
                        </div>
                    );
                },
                accessor: 'select',
            },
            {
                Header: t('Number'),
                accessor: 'index',
                width: 50,
                Cell: ({ row: { index } }: { row: { index: number } }) => {
                    const pageIndex = state?.request?.page ?? 1;
                    const orderIndex = (pageIndex - 1) * PAGE_SIZE + index + 1;
                    return <span>{orderIndex}</span>;
                },
            },
            {
                Header: t('Reason for Outstanding Amount'),
                accessor: 'unpaidType',
                width: 140,
                Cell: ({ value }: { value: UnpaidType }) => {
                    return value || '-';
                },
            },
            {
                Header: t('Dept'),
                accessor: 'departmentName',
                width: 90,
                Cell: ({ value }: { value: string }) => {
                    return value || '-';
                },
            },
            {
                Header: t('Date of received'),
                accessor: 'appointmentDate',
                width: 100,
                Cell: ({ value }: { value: NullableUnixTimestamp }) => (
                    <span>{value ? moment.unix(value).format(DATE_FORMAT.YMD) : '-'}</span>
                ),
            },
            {
                Header: t('Date of occurrence'),
                accessor: 'careEndDate',
                width: 100,
                Cell: ({ value }: { value: NullableUnixTimestamp }) => (
                    <span>{value ? moment.unix(value).format(DATE_FORMAT.YMD) : '-'}</span>
                ),
            },
            {
                Header: t('Outstanding Amount'),
                accessor: 'amount',
                width: 120,
                Cell: ({ value }: { value: string }) => {
                    return <span>{Number(value).toLocaleString()}</span>;
                },
            },
            {
                Header: t('Notification Send History'),
                accessor: 'historyCount',
                width: 120,
                Cell: ({ row: { original } }: { row: { original: DetailRow } }) => {
                    const { historyCount, unpaidAmountNum } = original;
                    return (
                        <span
                            className={'text-decoration-underline cursor-pointer'}
                            onClick={() => {
                                setUnpaidNum(unpaidAmountNum);
                                setHistoryOpen(true);
                            }}
                        >
                            {historyCount ?? 0}
                            {t(' times')}
                        </span>
                    );
                },
            },
        ],
        [t, checkedList, rows],
    );

    return (
        <>
            <Modal
                size={'lg'}
                initModal={isOpen}
                toggleModal={toggleModal}
                headerTitle={t('Outstanding Amount Detail History')}
                cancelCallback={() => {
                    refreshList();
                }}
                removeCancel={true}
                removeModalClose={false}
                modalFooter={<></>}
            >
                <ModalBody>
                    <div className={'d-flex gap-3 border border-1 p-2 mb-2'}>
                        <div className={'pnt-txt txt-bold fs-6'}>{patientInfo.patientId}</div>
                        <div className={'pnt-txt txt-bold fs-6'}>{patientInfo.patientName}</div>
                        <div className={'pnt-txt txt-bold fs-6'}>{formatPhoneNumber(patientInfo.phoneNum)}</div>
                    </div>
                    <TableWrapper>
                        <Table
                            columns={columns}
                            data={{ ...rows, pageSize: PAGE_SIZE }}
                            onPageChange={page => {
                                promise({ page });
                            }}
                        />
                    </TableWrapper>
                    <div className={'d-flex justify-content-center mt-3'}>
                        <SendContainer
                            checkedList={checkedList}
                            disabled={checkedList.length === 0}
                            refresh={promise}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {historyOpen && (
                <OutstandingHistory
                    isOpen={historyOpen}
                    toggleModal={() => {
                        setHistoryOpen(prev => !prev);
                    }}
                    unpaidNum={unpaidNum}
                />
            )}
        </>
    );
};

const TableWrapper = styled.div`
    height: 400px;
`;

export default OutstandingDetail;
