import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@hooks';
import { OUTPATIENT_REPORT } from '../../constants';

interface Props {
    selected: 'daily' | 'weekly' | 'monthly' | 'yearly';
    handleDateTypeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DateTypeGroup = ({ selected, handleDateTypeChange }: Props) => {
    const t = useTranslation(OUTPATIENT_REPORT);

    return (
        <div className={'d-flex gap-2'}>
            <label className={'d-flex align-items-center gap-2 mb-0'}>
                <RadioButton
                    type={'radio'}
                    className={'mr-0'}
                    value="daily"
                    checked={selected === 'daily'}
                    onChange={handleDateTypeChange}
                />
                {t('Day')}
            </label>
            <label className={'d-flex align-items-center gap-2 mb-0'}>
                <RadioButton
                    type={'radio'}
                    className={'mr-0'}
                    value="weekly"
                    checked={selected === 'weekly'}
                    onChange={handleDateTypeChange}
                />
                {t('Week')}
            </label>
            <label className={'d-flex align-items-center gap-2 mb-0'}>
                <RadioButton
                    type={'radio'}
                    className={'mr-0'}
                    value="monthly"
                    checked={selected === 'monthly'}
                    onChange={handleDateTypeChange}
                />
                {t('Month')}
            </label>
            <label className={'d-flex align-items-center gap-2 mb-0'}>
                <RadioButton
                    type={'radio'}
                    className={'mr-0'}
                    value="yearly"
                    checked={selected === 'yearly'}
                    onChange={handleDateTypeChange}
                />
                {t('Year')}
            </label>
        </div>
    );
};

export default DateTypeGroup;

const RadioButton = styled.input.attrs({ type: 'radio' })`
    -webkit-appearance: none; /* 웹킷 브라우저에서 기본 스타일 제거*/
    -moz-appearance: none; /* 모질라 브라우저에서 기본 스타일 제거*/
    appearance: none; /*기본 브라우저에서 기본 스타일 제거*/
    width: 15px;
    height: 15px;
    border: 2px solid #aaa;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: lightgray;
    transition: all 0.1s ease;

    &:checked {
        background-color: #6a55c0; /*체크 시 내부 원 색상*/
        border: 3px solid #fff; /*라인이 아닌, 라인과 원 사이 색상*/
        box-shadow: 0 0 0 1px #6a55c0; /*라인*/
    }
`;
