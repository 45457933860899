import React, { useMemo } from 'react';
import { LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import { OUTPATIENT_REPORT, TIME_LABELS, TIME_LABELS_DATA } from '../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { customLegendPlugin, hoverBackgroundPlugin } from '../util/chartPlugin';
import { fontColorByTheme } from '../util';

const Chart = ({ data }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const lineData = useMemo(
        () => ({
            labels: TIME_LABELS,
            datasets: data.map(({ departmentName, wait }) => ({
                label: departmentName,
                data: TIME_LABELS_DATA.map((item, index) => ({
                    ...item,
                    y: wait[index],
                })),
                datalabels: {
                    display: data.length === 1,
                    // 이 데이터셋에만 데이터 레이블 표시
                    anchor: 'end',
                    align: 'top',
                    color: fontColorByTheme(colorScheme),
                    formatter: function (value) {
                        return `${value.y} ${t('Minute')}`; // 표시할 값
                    },
                },
                tension: 0.4,
            })),
        }),
        [data, t, colorScheme],
    );

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Average Outpatient Waiting Time by Time Slot') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div
                    id="custom-legend-container"
                    className={'d-flex gap-2 position-absolute'}
                    style={{
                        top: 0,
                        right: 0,
                    }}
                />
                <LineChart
                    data={lineData}
                    plugins={[ChartDataLabels, hoverBackgroundPlugin, customLegendPlugin]}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                top: 60,
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: true, // 툴팁 활성화
                                intersect: false,
                                mode: 'index', // 가장 가까운 데이터 포인트에 반응하도록 설정
                                callbacks: {
                                    title: function (tooltipItem) {
                                        const { range } = tooltipItem[0].raw;
                                        return range;
                                    },
                                    // 툴팁에 표시할 내용을 설정
                                    label: function (tooltipItem) {
                                        const { y } = tooltipItem.raw; // 해당 축 데이터
                                        const { label } = tooltipItem.dataset;

                                        return `${label}: ${y} ${t('Minute')}`; // 툴팁에 표시될 내용
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    stepSize: 30,
                                    displayFormats: {
                                        minute: 'HH:mm',
                                    },
                                },
                                title: {
                                    display: true,
                                    text: t('Kiosk Usage Time'),
                                    font: {
                                        size: 16,
                                    },
                                    padding: { top: 20 },
                                },
                            },
                            y: {
                                grace: '20%',
                                beginAtZero: true,
                                suggestedMax: data.length === 1 ? Math.max(...data[0].wait) + 10 : 10,
                                title: {
                                    display: true,
                                    text: t('Outpatient Waiting Time (minutes)'),
                                    font: {
                                        size: 15,
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </InnerItemCard>
    );
};

export default Chart;
