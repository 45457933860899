import { fontColorByTheme } from './index';

// 차트 호버시 백그라운드를 입히는 플러그인
export const hoverBackgroundPlugin = {
    id: 'so/hoverBackgroundPlugin',
    beforeDraw: chart => {
        const {
            ctx,
            tooltip,
            chartArea: { top, bottom, left, right, width, height },
            scales: { x, y },
        } = chart;

        if (tooltip._active[0]) {
            const index = tooltip._active[0].index;

            // 그리드 라인 항목의 x1, x2 값을 이용해 정확한 영역을 계산
            const startX = x._gridLineItems[index].x1; // 해당 x값 시작
            const endX = x._gridLineItems[index + 1] ? x._gridLineItems[index + 1].x1 : startX + 10; // 끝 x값 (다음 그리드 라인까지)

            // 호버된 영역에 대한 배경색을 그린다.
            ctx.fillStyle = 'rgba(255, 239, 176, 0.49)';
            ctx.fillRect(startX, top, endX - startX, height); // x1 ~ x2 사이에 배경색 채우기
        }
    },
};

// 커스텀 legend를 띄우는 Plugin
// custom-legend-container id를 가진 div요소가 반드시 필요
export const customLegendPlugin = {
    id: 'so/customLegendPlugin',
    afterUpdate: chart => {
        const legendContainer = document.getElementById('custom-legend-container');
        if (!legendContainer) return;

        // Clear previous legend
        while (legendContainer.firstChild) {
            legendContainer.firstChild.remove();
        }

        // Create new legend items
        chart.data.datasets.forEach((dataset, index) => {
            const legendItem = document.createElement('div');
            legendItem.style.display = 'flex';
            legendItem.style.alignItems = 'center';
            legendItem.style.cursor = 'pointer';

            // Legend color box
            const colorBox = document.createElement('span');
            colorBox.style.backgroundColor = dataset.backgroundColor;
            colorBox.style.width = '12px';
            colorBox.style.height = '12px';
            colorBox.style.display = 'inline-block';
            colorBox.style.marginRight = '8px';

            if (dataset.type === 'line') {
                colorBox.style.borderRadius = '15px';
            }

            legendItem.appendChild(colorBox);

            // Legend label
            const label = document.createElement('span');
            label.textContent = dataset.label;
            legendItem.appendChild(label);

            // Add event listener to toggle dataset visibility
            legendItem.addEventListener('click', () => {
                const meta = chart.getDatasetMeta(index);
                meta.hidden = !meta.hidden;
                chart.update();
            });

            legendContainer.appendChild(legendItem);
        });
    },
};

// 진료과별 사용량분석 에서만 사용하는 Plugin
export const topLabels = {
    id: 'so/topLabels',
    afterDatasetDraw: (chart, args, pluginOptions) => {
        const { ctx } = chart;

        chart.data.datasets[2].data.forEach((datapoint, index) => {
            ctx.font = 'bold 14px';
            ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
            ctx.textAlign = 'center';
            ctx.fillText(datapoint, chart.getDatasetMeta(2).data[index].x, chart.getDatasetMeta(2).data[index].y - 10);
        });

        chart.data.datasets[0].data.forEach((datapoint, index) => {
            const datasetArray = [];

            chart.data.datasets.forEach(dataset => {
                if (dataset.stack === 'patient') {
                    datasetArray.push(dataset.data[index]);
                }
            });

            let sum = datasetArray.reduce((acc, curr) => acc + curr, 0);

            ctx.font = 'bold 14px';
            ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
            ctx.textAlign = 'center';
            ctx.fillText(sum, chart.getDatasetMeta(1).data[index].x, chart.getDatasetMeta(1).data[index].y - 10);
        });
    },
};
