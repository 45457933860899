import { OCCUR_STATE_INFO, TAG_STATE_LABEL } from '@util/mappInfo';

export const convertState = (state, mappInfo = OCCUR_STATE_INFO) => {
    const convertedState = {};
    for (let key in state) {
        if (mappInfo[key]) {
            convertedState[key] = mappInfo[key][state[key]];
        }
    }
    return convertedState;
};

export const setTagStatus = (tagStatus, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const state = tagInfo.state;
    for (let key in tagStatus) {
        const status = tagStatus[key];
        const existIdx = status.indexOf(targetNum);
        if (existIdx > -1) {
            status.splice(existIdx, 1);
        }
    }
    for (let key in state) {
        const isOn = state[key];
        if (isOn) {
            if (tagStatus[key]) {
                tagStatus[key].push(targetNum);
            } else {
                tagStatus[key] = [targetNum];
            }
        }
    }
};
export const setTagAlarmList = (tagAlarmList, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const { state, recovery, target } = tagInfo;
    const alarmRowId = createAlarmId(state, targetNum);
    const recoveryRowId = createAlarmId(recovery, targetNum);
    alarmRowId.forEach(alarmId => {
        const existIdx = tagAlarmList.findIndex(v => v.alarmId === alarmId);
        if (existIdx > -1) {
            tagAlarmList.splice(existIdx, 1);
        }
        const tagState = alarmId.split('_')[0];
        tagAlarmList.unshift({
            ...tagInfo,
            alarmId,
            targetId: target.targetId,
            targetName: target.targetName,
            tagState,
            tagStateLabel: TAG_STATE_LABEL[tagState],
            categoryName: target.categoryName,
        });
    });
    recoveryRowId.forEach(alarmId => {
        const existIdx = tagAlarmList.findIndex(v => v.alarmId === alarmId);
        if (existIdx > -1) {
            tagAlarmList.splice(existIdx, 1);
        }
    });
};
export const createAlarmId = (state, targetNum) => {
    const rowId = [];
    for (let key in state) {
        if (state[key]) {
            rowId.push(`${key}_${targetNum}`);
        }
    }
    return rowId;
};
