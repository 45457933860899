import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAsync, useTranslation } from '@hooks';
import cx from 'classnames';
import { ScatterDataPoint } from 'chart.js';
import { TICK_LIMITS } from './constants';
import { MODEL_TO_SENSOR_TYPE, SENSOR_TYPE, SENSOR_TYPE_TO_NICKNAME } from '@util/staticData/sensorMeta';
import VitalSignsChart from './VitalSignsChart';
import { Nullable, Timestamp } from '@util/type/util';
import { getStartOfDate } from '@util/date';
import moment from 'moment';
import styled from 'styled-components';
import {
    getMinMaxSensingValuesApi,
    getSensorLogApi,
    getSensorRangeInfoApi,
    MinMaxSensingValues,
    SensorRangeInfo,
} from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE } from '@util/staticData/sensorMeta';
import { DatePicker } from '@components';
import Button from '@components/Button';
import { usePatientDetailsStateContext } from './slice';

type SensorType = 'HEARTBEAT' | 'RESPIRATION_RATE' | 'TEMPERATURE' | 'BATTERY';
export type ChartSensorType = Exclude<SensorType, 'BATTERY'>;

export type ChartData = {
    data: (number | ScatterDataPoint | null)[];
    labels: number[];
    sensorType: ChartSensorType;
    name: string;
    unit: string;
    min: number;
    max: number;
};

export type ChartLoading = {
    HEARTBEAT: boolean;
    RESPIRATION_RATE: boolean;
    TEMPERATURE: boolean;
};

const getInitialChartData = (sensorType: ChartSensorType, name: string) => ({
    data: [],
    labels: [getStartOfDate() * 1000, moment().valueOf()],
    sensorType: sensorType,
    name: name,
    unit: '',
    min: Infinity,
    max: -Infinity,
});

type VitalSignsViewProps = {
    targetNum?: number;
};

const SENSOR_TYPE_ORDER = MODEL_TO_SENSOR_TYPE[MODEL_CODE.MEZOO_SMARTPATCH] ?? [];

const VitalSignsView = ({ targetNum }: VitalSignsViewProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const [searchDate, setSearchDate] = useState(moment().unix());
    const { mappedSensorNum } = usePatientDetailsStateContext();

    const [chartData, setChartData] = useState<{
        HEARTBEAT: ChartData;
        RESPIRATION_RATE: ChartData;
        TEMPERATURE: ChartData;
    }>({
        HEARTBEAT: getInitialChartData('HEARTBEAT', t(SENSOR_TYPE_TO_NICKNAME.HEARTBEAT ?? '')),
        RESPIRATION_RATE: getInitialChartData('RESPIRATION_RATE', t(SENSOR_TYPE_TO_NICKNAME.RESPIRATION_RATE ?? '')),
        TEMPERATURE: getInitialChartData('TEMPERATURE', t(SENSOR_TYPE_TO_NICKNAME.TEMPERATURE ?? '')),
    });
    const [chartLoading, setChartLoading] = useState<ChartLoading>({
        HEARTBEAT: true,
        RESPIRATION_RATE: true,
        TEMPERATURE: true,
    });

    const {
        state: { isLoading },
    } = useAsync({
        promise: getSensorLogApi,
        fixedParam: {
            targetNum: targetNum,
            modelCode: MODEL_CODE.MEZOO_SMARTPATCH,
            startDate: moment.unix(searchDate).startOf('d').unix(),
            endDate: moment.unix(searchDate).endOf('d').unix(),
        },
        immediate: true,
        deps: [targetNum, searchDate],
        resolve: ({ sensingList, measurementUnits }: any, { startDate, endDate }) => {
            if (sensingList) {
                const dateList = sensingList.regDate ?? [];
                SENSOR_TYPE_ORDER.forEach(sensorType => {
                    const list = sensingList[sensorType] ?? [];
                    const stepSize = TICK_LIMITS;
                    const startTime = (startDate ?? dateList[0]) * 1000;
                    const endTime = (endDate ?? dateList[dateList.length - 1]) * 1000;
                    const offset = Math.floor((endTime + 1000 - startTime) / stepSize);
                    const labels: Timestamp[] = [];
                    if (list.length) {
                        labels.push(startTime);
                        for (let i = 0; i < stepSize; i++) {
                            labels.push(startTime + offset * i);
                        }
                        labels.push(endTime);
                    } else {
                        labels.push(getStartOfDate() * 1000, moment().valueOf());

                        setChartLoading(prev => ({ ...prev, [sensorType]: false }));
                    }

                    setChartData(prev => {
                        return {
                            ...prev,
                            [sensorType]: list.reduce(
                                (acc: ChartData, currentValue: number, idx: number) => {
                                    acc.data.push({
                                        x: dateList[idx] * 1000,
                                        y: currentValue,
                                    });
                                    acc.min = Math.min(acc.min, currentValue);
                                    acc.max = Math.max(acc.max, currentValue);
                                    return acc;
                                },
                                {
                                    data: [],
                                    labels,
                                    sensorType,
                                    name: t(SENSOR_TYPE_TO_NICKNAME[sensorType] ?? ''),
                                    unit: measurementUnits[sensorType] ?? '',
                                    min: Infinity,
                                    max: -Infinity,
                                },
                            ),
                        };
                    });
                });
            }
        },
        reject: err => {
            setChartLoading({ HEARTBEAT: false, RESPIRATION_RATE: false, TEMPERATURE: false });
            console.error(err);
        },
    });

    const {
        state: { response: criticalMinMax, isLoading: isMinMaxLoading },
    } = useAsync({
        promise: getMinMaxSensingValuesApi,
        fixedParam: {
            targetNum: targetNum,
            modelCode: MODEL_CODE.MEZOO_SMARTPATCH,
            startDate: moment.unix(searchDate).startOf('d').unix(),
            endDate: moment.unix(searchDate).endOf('d').unix(),
            sensorStatusTypeList: ['CRITICAL'],
        },
        immediate: true,
        deps: [targetNum, searchDate],
    });

    const {
        promise: getSensorRangeInfo,
        state: { response: rangeInfo },
    } = useAsync({
        promise: getSensorRangeInfoApi,
        fixedParam: {
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            targetNum,
        },
        resolve: response => {
            const { sensorNum, modelCode, ...restInfo } = response;
        },
    });

    useEffect(() => {
        if (mappedSensorNum) {
            getSensorRangeInfo({ sensorNum: mappedSensorNum });
        }
    }, [mappedSensorNum]);

    useEffect(() => {
        return () => {
            setChartData({
                HEARTBEAT: getInitialChartData('HEARTBEAT', t(SENSOR_TYPE_TO_NICKNAME.HEARTBEAT ?? '')),
                RESPIRATION_RATE: getInitialChartData(
                    'RESPIRATION_RATE',
                    t(SENSOR_TYPE_TO_NICKNAME.RESPIRATION_RATE ?? ''),
                ),
                TEMPERATURE: getInitialChartData('TEMPERATURE', t(SENSOR_TYPE_TO_NICKNAME.TEMPERATURE ?? '')),
            });
        };
    }, [searchDate, t]);

    return (
        <VitalSignsWrapper className={cx('p-3 border border-depth-7')}>
            <div className={'chart-header'}>
                <span className={'pnt-txt txt-dot txt-bold s-6'}>{t('Daily graphs')}</span>
                <span style={{ width: '200px' }}>
                    <DatePicker
                        withoutTime
                        maxDate={moment().valueOf()}
                        value={searchDate}
                        handleChange={selected => {
                            if (selected) {
                                setSearchDate(selected);
                            } else {
                                setSearchDate(moment().unix());
                            }
                        }}
                    />
                </span>
                <div className={'flex-center align-items-baseline'}>
                    <Button
                        className="btn-icon-only btn-gray"
                        iconName="keyboard_arrow_left"
                        onClick={() => {
                            setSearchDate(moment.unix(searchDate).subtract(1, 'day').unix());
                        }}
                    />
                    <Button
                        className={'btn-icon-only btn-gray ml-1'}
                        iconName="keyboard_arrow_right"
                        onClick={() => {
                            setSearchDate(moment.unix(searchDate).add(1, 'day').unix());
                        }}
                        disabled={moment.unix(searchDate).isSame(moment(), 'day')}
                    />
                </div>
            </div>
            <PerfectScrollbar className={'chart-container'}>
                {SENSOR_TYPE_ORDER.map(sensorType => {
                    const currSensorType = sensorType as ChartSensorType;
                    return (
                        <VitalSignsChart
                            key={sensorType}
                            chartData={chartData[currSensorType]}
                            isLoading={isLoading || chartLoading[currSensorType] || isMinMaxLoading}
                            setLoading={setChartLoading}
                            criticalMinMaxValue={getCriticalMinMaxValue(criticalMinMax, currSensorType)}
                            normalRange={getNormalRange(rangeInfo, currSensorType)}
                        />
                    );
                })}
            </PerfectScrollbar>
        </VitalSignsWrapper>
    );
};

const getCriticalMinMaxValue = (criticalMinMax: Nullable<MinMaxSensingValues>, sensorType: ChartSensorType) => {
    return {
        min: getCriticalValue(criticalMinMax, sensorType, 'min'),
        max: getCriticalValue(criticalMinMax, sensorType, 'max'),
    };
};

const getCriticalValue = (
    criticalMinMax: Nullable<MinMaxSensingValues>,
    sensorType: ChartSensorType,
    minmax: 'min' | 'max',
) => {
    if (criticalMinMax && criticalMinMax[sensorType]) {
        if (['RESPIRATION_RATE', 'HEARTBEAT'].includes(sensorType) && criticalMinMax[sensorType][minmax] === 0) {
            return null;
        }
        return criticalMinMax[sensorType][minmax];
    }
    return null;
};

const getNormalRange = (
    rangeInfo: Nullable<SensorRangeInfo>,
    sensorType: ChartSensorType,
): Nullable<[min: number, max: number]> => {
    if (rangeInfo && sensorType !== SENSOR_TYPE.RESPIRATION_RATE) {
        if (
            typeof rangeInfo.minControlLimit[sensorType] === 'number' &&
            typeof rangeInfo.maxControlLimit[sensorType] === 'number'
        ) {
            return [rangeInfo.minControlLimit[sensorType]!, rangeInfo.maxControlLimit[sensorType]!];
        }
    }
    return null;
};

const VitalSignsWrapper = styled.div`
    display: grid;
    grid-template-rows: auto;
    row-gap: 2rem;

    & .chart-header {
        display: grid;
        grid-template-columns: max-content 200px max-content;
        column-gap: 1.5rem;
    }
    & .chart-container {
        display: grid;
        grid-auto-rows: min-content;
        row-gap: 1rem;
    }
`;

export default VitalSignsView;
