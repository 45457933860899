import React, { useEffect, useMemo, useRef, useState } from 'react';
import useAsync from '@hooks/useAsync';
import { fetchGeofenceList } from '@api/common/geofence';
import { useAppSelector, useTranslation } from '@hooks';
import { defaultOptions } from '@components/Map/config';
import { Nullable, NullableNumber } from '@util/type/util';
import { GeofenceInfo, GeofenceResponse, LatLngList, UserInfo } from '../type';
import { FloorInfo as OriginFloorInfo } from '@api/common/floor';
import Map from '@components/Map/Components/Map';
import { GeofenceLayer, RotatedImageOverlay } from '@components';

interface Props {
    floorId: Nullable<string>;
    fcNum: Nullable<number>;
}
type LatLng = [lat: NullableNumber, lng: NullableNumber];
type Bounds = [swLatLng: LatLng, neLatLng: LatLng];
interface FloorInfo extends OriginFloorInfo {
    bounds: Bounds;
}

interface GeofenceItem extends GeofenceInfo {
    bounds: Bounds[];
}

const GeofenceStatus = ({ floorId, fcNum }: Props) => {
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });

    const t = useTranslation('IdleEquipment');

    const { floorList } = useAppSelector(state => state.FloorInfo);
    const { userInfo } = useAppSelector(state => state.UserInfo);

    const mapRef = useRef<any>(null);
    const [floorInfo, setFloorInfo] = useState<FloorInfo>();
    const [geofenceInfo, setGeofenceInfo] = useState<GeofenceItem[]>([]);
    const [selectedGeofence, setSelectedGeofence] = useState<LatLngList>();

    const mapDefaultCenter = useMemo(() => {
        let center = defaultOptions.center;
        const { companyInfo } = userInfo as UserInfo;
        if (companyInfo && companyInfo.lat) {
            center = [companyInfo.lat, companyInfo.lng];
        }
        return center;
    }, [userInfo]);

    useEffect(() => {
        if (floorInfo?.floorId) {
            getGeofenceList({ floor: floorId });
        } else {
            const currentFloor = floorList.find(e => e.floorId === floorId);
            setFloorInfo(currentFloor);
        }
    }, [floorInfo]);

    useEffect(() => {
        const { response } = (getGeofenceInfo as unknown) as GeofenceResponse;
        if (response) {
            const selectedGeofenceState = response.rows?.find(v => v.fcNum === fcNum);
            const geofenceItem = response.rows.map(geofence => {
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng]) as Bounds],
                };
            });

            setSelectedGeofence(selectedGeofenceState?.latLngList[0]);
            setGeofenceInfo(geofenceItem);
        }
    }, [getGeofenceInfo]);

    const geofence = useMemo(() => geofenceInfo.find(v => v.fcNum === fcNum), [geofenceInfo]);
    return (
        <div className={'w-100 h-100 flx-col'}>
            <div className={'d-flex gap-2'}>
                <span className={'color-secondary s-6 pnt-txt txt-bold'}>{`${t('floor')} :`}</span>
                <span className={'color-secondary s-6 pnt-txt txt-bold'}>{`${floorInfo?.floorName ?? '-'}`}</span>
                <span className={'color-secondary s-6 pnt-txt txt-bold'}>{` / `}</span>
                <span className={'color-secondary s-6 pnt-txt txt-bold'}>{`${t('location')} :`}</span>
                <span className={'color-secondary s-6 pnt-txt txt-bold'}>{`${geofence?.fcName ?? ' - '}`}</span>
            </div>

            <Map
                tile={undefined}
                ref={mapRef}
                rotation={floorInfo?.rotation}
                center={selectedGeofence ? selectedGeofence : mapDefaultCenter}
                className={'hide-mapControl'}
            >
                {floorInfo?.imgURL && (
                    <RotatedImageOverlay
                        key={floorInfo?.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={(e: any) => {
                            const { target: layer } = e;
                            layer._map.fitBounds(layer.getBounds());
                        }}
                    />
                )}
                {geofenceInfo.length > 0 && (
                    <GeofenceLayer
                        geofenceList={geofenceInfo}
                        selectedGeofence={undefined}
                        handleClick={undefined}
                        authState={undefined}
                    />
                )}
            </Map>
        </div>
    );
};

export default GeofenceStatus;
