import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FeatureGroup, Popup } from 'react-leaflet';
import { Container, Row } from 'reactstrap';
import L from 'leaflet';

import { AniMarker, Button, ConfirmModal, Map, RotatedImageOverlay } from '@components';
import { isIn } from '@util/mappInfo';
import { useAsync, useTranslation } from '@hooks';
import useAppSelector from '@hooks/useAppSelector';
import useBookmarkedList from '@hooks/commonData/useBookmarkedList';
import { getIotItemApi } from '@api/common/asset';
import { fetchGeofenceList } from '@api/common/geofence';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';

import { TARGET_OPTION_ALL_TARGET, TARGET_OPTION_SINGLE_TARGET } from './RealTimeLocationSetting';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { FilterList, InputGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import TargetSearchSelect from './Components/TargetSearchSelect';
import WidgetCard from '../../Components/WidgetCard';
import {
    setCategoryGroup,
    setCategoryImg,
    setCategoryList,
    setFloorGroup,
    setFloorInfo,
    setGeofenceInfo,
    setItem,
    setRealTimeLog,
    setSelectedFloor,
    setSelectedItem,
} from './realTimeLocationSlice';
import AllTarget from './Components/AllTarget';
import SingleTarget from './Components/SingleTarget';
import SelectedItemDetail from './Components/SelectedItemDetail';
import { useSettings } from '../../util/useSettings';
import useTargetTitle, { isLostSignal } from './Components/TargetSearchSelect/useTargetTitle';
import { convertState } from '@reducer/Common/processSocketData';
import useGroupFloor from './useGroupFloor';
import { useRealTimeLocationStatusContext, useRealTimeLocationStatusDispatch } from './index';

const RealTimeLocationStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const { representationGroupInfo } = useAppSelector(state => state.UserInfo.userInfo);
    const { categoryList, categoryToImg } = useAppSelector(state => state.CategoryInfo);
    const { floorList } = useAppSelector(state => state.FloorInfo);
    const {
        floorInfo,
        selectedFloor,
        selectedItem,
        realTimeLog,
        bookmark,
        selectedCategory,
    } = useRealTimeLocationStatusContext();
    const dispatch = useRealTimeLocationStatusDispatch();
    const bookmarkedList = useBookmarkedList();

    const [openPopup, setOpenPopup] = useState(false);
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [zoom, setZoom] = useState(null);

    const mapRef = useRef();
    const widgetRef = useRef();
    const currFloorTags = useRef({});

    const subTitle = useTargetTitle(selectedItem, true);

    const { promise: getAsset } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            dispatch(setItem(res));
        },
        reject: err => console.error(err),
    });

    const handleSelectInfo = targetNum => () => {
        getAsset({ targetNum });
    };

    const handlePopupOpen = () => {
        setOpenPopup(true);
    };

    useEffect(() => {
        dispatch(setCategoryList(categoryList));
        dispatch(setCategoryGroup(categoryList));
        dispatch(setCategoryImg(categoryToImg));
    }, [categoryList, categoryToImg]);

    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });

    useEffect(() => {
        if (settings.targetOption === TARGET_OPTION_SINGLE_TARGET) {
            const selectedTargetId = selectedItem?.targetId;
            if (selectedTargetId && currFloorTags.current[selectedTargetId]) {
                currFloorTags.current = { [selectedTargetId]: currFloorTags.current[selectedTargetId] };
            } else {
                currFloorTags.current = {};
            }
        } else {
            for (const [tagNum, tagInfo] of Object.entries(currFloorTags.current)) {
                if (
                    (selectedCategory.length && !selectedCategory.includes(tagInfo.targetCategory.categoryCode)) ||
                    (bookmark && !bookmarkedList.includes(tagInfo.target.targetNum))
                ) {
                    currFloorTags.current[tagNum] = null;
                    delete currFloorTags.current[tagNum];
                }
            }
        }
        const tagList = Object.values(currFloorTags.current);
        dispatch(
            setRealTimeLog({
                count: tagList.length,
                data: tagList,
            }),
        );
    }, [selectedCategory, bookmark, bookmarkedList, selectedItem]);

    useEffect(() => {
        currFloorTags.current = {};
        dispatch(setRealTimeLog({ count: 0, data: [] }));

        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            const map = mapRef.current.leafletElement;
            if (map && settings.targetOption === TARGET_OPTION_ALL_TARGET) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        } else {
            dispatch(setGeofenceInfo({ geofenceInfo: [] }));
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            dispatch(setGeofenceInfo({ geofenceInfo: response.rows }));
        }
    }, [getGeofenceInfo]);

    const socketFilterConfig = useMemo(() => {
        const resultConfig = {};

        if (settings.targetOption === TARGET_OPTION_SINGLE_TARGET) {
            if (selectedItem?.targetId) {
                resultConfig.target = { targetId: selectedItem.targetId };
            } else {
                resultConfig.target = { noTarget: true };
            }
        } else {
            if (selectedFloor) {
                resultConfig.$or = [
                    { currentFloor: { floorId: selectedFloor } },
                    { previousFloor: { floorId: selectedFloor } },
                ];
            } else {
                resultConfig.currentFloor = { noneFloor: true };
            }
            if (selectedCategory && selectedCategory.length) {
                resultConfig.targetCategory = {
                    categoryCode: {
                        $in: selectedCategory,
                    },
                };
            }
            if (bookmark) {
                resultConfig.target = { targetNum: { $in: bookmarkedList } };
            }
        }
        return resultConfig;
    }, [settings, selectedFloor, selectedCategory, bookmark, bookmarkedList, selectedItem]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        handler: data => {
            // console.log('realtime data', data);
            const {
                target,
                targetState,
                currentFloor,
                targetCategory: { categoryCode },
                latlng: { lat, lng },
            } = data;
            const { lostSignal } = convertState(targetState);
            const targetData = {
                ...data,
                target: { ...target, categoryCode: categoryCode },
                location: {
                    lat,
                    lng,
                    latLng: [lat, lng],
                },
                floorInOutState: {
                    floorId: currentFloor.floorId,
                    floorName: currentFloor.floorName,
                    isIn: isIn(currentFloor.inOutState),
                    inTime: currentFloor.inUnixTime,
                    stayTime: currentFloor.stayUnixTime,
                    outTime: currentFloor.outUnixTime,
                },
            };
            if (settings.targetOption === TARGET_OPTION_ALL_TARGET) {
                if (!lostSignal && socketFilterConfig?.$or?.[0]?.currentFloor?.floorId === currentFloor.floorId) {
                    currFloorTags.current[target.targetNum] = targetData;
                } else if (currFloorTags.current[target.targetNum]) {
                    currFloorTags.current[target.targetNum] = null;
                    delete currFloorTags.current[target.targetNum];
                }
            } else {
                if (lostSignal) {
                    currFloorTags.current[target.targetNum] = null;
                    delete currFloorTags.current[target.targetNum];
                } else {
                    if (floorInfo.floorId !== currentFloor.floorId) {
                        const currentFloorInfo = floorList.find(floor => floor.floorId === currentFloor.floorId);
                        if (currentFloorInfo) {
                            dispatch(setSelectedFloor(currentFloor.floorId));
                            dispatch(setFloorInfo(currentFloorInfo));
                        }
                    }
                    currFloorTags.current[target.targetNum] = targetData;
                }
            }

            const tagList = Object.values(currFloorTags.current);
            dispatch(
                setRealTimeLog({
                    count: tagList.length,
                    data: tagList,
                }),
            );
        },
        filterConfig: socketFilterConfig,
    });

    useGroupFloor({
        handleChangeFloor: floorInfo => {
            if (floorInfo?.upperFloorId) {
                dispatch(setFloorGroup(floorInfo));
            }
        },
    });

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleModalToggle = () => {
        setStatusModal(!statusModal);
    };

    return (
        <WidgetCard
            bodyClassName={'p-1'}
            widgetInfo={widgetInfo}
            ref={widgetRef}
            subTitle={
                settings.targetOption === TARGET_OPTION_ALL_TARGET
                    ? t('All Target')
                    : settings.targetOption === TARGET_OPTION_SINGLE_TARGET &&
                      !selectedItem.hasOwnProperty('targetName')
                    ? t('Please Search For An Item Under The Search Area') + t('(Single Target)')
                    : subTitle
            }
            headerAction={
                settings.targetOption === TARGET_OPTION_SINGLE_TARGET && (
                    <>
                        <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                            {t('Search', 'Button')}
                        </Button>
                    </>
                )
            }
            searchFilter={
                settings.targetOption === TARGET_OPTION_SINGLE_TARGET &&
                showSearchArea && (
                    <FilterSearchGroup className={'card absolute-filter'}>
                        <SearchGroup label={t('Search', 'AssetCountStatus')}>
                            <FilterList>
                                <InputGroup>
                                    <TargetSearchSelect
                                        handleChangeSelect={selected => {
                                            dispatch(setSelectedItem(selected));
                                            if (selected && isLostSignal(selected)) {
                                                setStatusModal(true);
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FilterList>
                        </SearchGroup>
                    </FilterSearchGroup>
                )
            }
            {...restProps}
        >
            <Map
                tile={confirmedTile}
                ref={mapRef}
                onZoomend={e => {
                    setZoom(e.target.getZoom());
                }}
                rotation={floorInfo?.rotation}
            >
                {settings.targetOption === TARGET_OPTION_ALL_TARGET ? (
                    <AllTarget widgetRef={widgetRef} mapRef={mapRef} />
                ) : (
                    <SingleTarget mapRef={mapRef} />
                )}
                {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                    <RotatedImageOverlay
                        key={floorInfo.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={() => {
                            const map = mapRef.current.leafletElement;
                            if (map) {
                                if (realTimeLog.data[0] && settings.targetOption === TARGET_OPTION_SINGLE_TARGET) {
                                    map.setView(realTimeLog.data[0].location.latLng);
                                } else if (
                                    representationGroupInfo?.floorId &&
                                    representationGroupInfo.floorId === floorInfo.floorId &&
                                    representationGroupInfo.lat &&
                                    representationGroupInfo.lng
                                ) {
                                    map.setView(
                                        [representationGroupInfo.lat, representationGroupInfo.lng],
                                        representationGroupInfo.zoom,
                                    );
                                }
                            }
                        }}
                    />
                )}
                <FeatureGroup>
                    {realTimeLog.data.map(log => {
                        const {
                            target: { targetId, targetName, targetNum },
                            targetCategory: { categoryName },
                        } = log;
                        return (
                            <AniMarker
                                key={targetNum}
                                targetInfo={log}
                                zoom={zoom}
                                onClick={handleSelectInfo(targetNum)}
                            >
                                <Popup attribution={{ autoClose: false }}>
                                    <div className={'d-flex realTime-label'}>
                                        <div className={'w-50'}>{t('IoT Item ID')}</div>
                                        <span className={'mx-2'}>|</span>
                                        <div className={'w-50'}>{targetId}</div>
                                    </div>
                                    <div className={'d-flex realTime-label'}>
                                        <div className={'w-50'}>{t('IoT Item Name')}</div>
                                        <span className={'mx-2'}>|</span>
                                        <div className={'w-50'}>{targetName}</div>
                                    </div>
                                    <div className={'d-flex realTime-label'}>
                                        <div className={'w-50'}>{t('Category Name')}</div>
                                        <span className={'mx-2'}>|</span>
                                        <div className={'w-50'}>{categoryName}</div>
                                    </div>
                                    <div className={'more'} onClick={handlePopupOpen}>
                                        {t('More')}
                                    </div>
                                </Popup>
                            </AniMarker>
                        );
                    })}
                </FeatureGroup>
                {openPopup && (
                    <SelectedItemDetail
                        modal={openPopup}
                        toggleModal={() => setOpenPopup(false)}
                        widgetRef={widgetRef}
                        mapRef={mapRef}
                    />
                )}
            </Map>

            <ConfirmModal
                initModal={statusModal}
                header={{ title: t('Search Result', 'Search') }}
                toggleModal={handleModalToggle}
                confirmText={
                    <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                        <Row className={'mb-1'}>
                            {t('The item you selected can not be tracked because there is no signal.')}
                        </Row>
                        <Row className={'mb-1'}>
                            {t('The item may be out of range or there might be a problem with the tag.')}
                        </Row>
                        <Row className={'mb-1'}>
                            {t('If there is an issue, please contact your system administrator.')}
                        </Row>
                    </Container>
                }
                removeCancel={true}
            />
            {children}
        </WidgetCard>
    );
};

export default RealTimeLocationStatusContainer;
