import React, { useState } from 'react';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import { useTranslation } from '@util/hooks';
import { Button, DatePicker, SearchableSelect, TextInput } from '@components';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';

const SearchGroup = ({ initialFilter, options, onSubmit, onRefreshClick, onDownloadClick }) => {
    const t = useTranslation('OutpatientStorage');

    const [filterDraft, setFilterDraft] = useState(initialFilter);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(filterDraft);
    };

    const handleRefreshClick = () => {
        setFilterDraft(initialFilter);
        onRefreshClick();
    };

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup>
                <FilterList>
                    <SearchableSelect
                        title={t('Reason for Outstanding Amount')}
                        data={options}
                        selected={filterDraft.type}
                        onChange={selected => setFilterDraft({ ...filterDraft, type: selected.map(v => v.value) })}
                        className="mr-2"
                    />
                    <DatePicker
                        value={filterDraft.startDate}
                        handleChange={selected => setFilterDraft({ ...filterDraft, startDate: selected })}
                        withoutTime
                        maxDate={filterDraft.endDate * 1000}
                        disabledKeyboardNavigation
                    />
                    -
                    <DatePicker
                        value={filterDraft.endDate}
                        handleChange={selected => setFilterDraft({ ...filterDraft, endDate: selected })}
                        withoutTime
                        minDate={filterDraft.startDate * 1000}
                        disabledKeyboardNavigation
                    />
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex">
                            <TextInput
                                value={filterDraft.keyword}
                                placeholder={t('Enter the patients name or patients ID.')}
                                handleChange={e => setFilterDraft({ ...filterDraft, keyword: e.target.value })}
                            />
                            <Button type="submit" className="btn-secondary">
                                {t('Search', 'Search')}
                            </Button>
                            <Button
                                className="btn-line btn-icon-only ml-2"
                                iconName="refresh"
                                onClick={handleRefreshClick}
                            />
                        </div>
                    </form>
                </FilterList>
            </FilterGroup>
            {/*<FilterGroup label={t('Filter', 'Filter')}>*/}
            {/*    사용할 수도 있는 기능으로 주석처리만  해놓음*/}
            {/*    <Button className="btn-secondary ms-auto" onClick={onDownloadClick}>*/}
            {/*        {t('Download')}*/}
            {/*    </Button>*/}
            {/*</FilterGroup>*/}
        </FilterSearchGroup>
    );
};

export default SearchGroup;
