import React, { useRef, useState } from 'react';
import { useAsync, useTranslation } from '@util/hooks';
import HistoryList from './Components/HistoryList';
import SearchGroup from './Components/SearchGroup';
import moment from 'moment';
import { getNotificationHistoryList, postNotificationHistoryExcel } from '@api/so/outpatient';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import WidgetCard from '../../Components/WidgetCard';
import { WidgetProps } from '../../staticInfo';
import { UnixTimestamp } from '@util/type/util';

export type SendingType = 'WT' | 'OD' | 'AP' | 'UP'; // 진료대기/진료차례/진료예약/미수금

export type UnpaidType = '[미수]외래현금미수' | '[미수]입원현금미수';

export interface Row {
    unpaidType: UnpaidType;
    patientId: string;
    patientName: string;
    departmentName: string;
    appointmentDate: UnixTimestamp;
    careEndDate: UnixTimestamp;
    amount: string;
    sendingDate: UnixTimestamp;
}

interface Response {
    page: number;
    totalCount: number;
    totalPage: number;
    rows: Row[];
}

export interface Data extends Response {
    pageSize: number;
}

const DEFAULT_DATA: Data = {
    page: 0,
    totalCount: 0,
    totalPage: 0,
    pageSize: 0,
    rows: [],
};

export interface Filter {
    type: string[];
    keyword: string;
    startDate: number;
    endDate: number;
    page: number;
}

const getSelectOptions = (t: (text: string) => string, centerName: string | undefined) => {
    let options: { label: string; value: SendingType | UnpaidType }[] = [];
    if (centerName === 'wonju') {
        options = [
            { label: t('[Outstanding] Outpatient Cash Receivables'), value: '[미수]외래현금미수' },
            { label: t('[Outstanding] Inpatient Cash Receivables'), value: '[미수]입원현금미수' },
        ];
    }

    // 현재는 미사용중인 병원
    // 추후 사용가능성 있어 남겨둠
    if (centerName === 'andong') {
        options = [
            { label: t('Wait'), value: 'WT' },
            { label: t('Order'), value: 'OD' },
            { label: t('Reservation'), value: 'AP' },
        ];
    }

    return { options };
};

const NotificationHistory = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('OutpatientStorage');
    const medicalCenterName = widgetInfo.config.settings?.categoryCode; // undefined|"wonju"|"andong"
    const { options } = getSelectOptions(t, medicalCenterName);

    const initialFilter = {
        type: options.map(ele => ele.value),
        keyword: '',
        startDate: moment().subtract(1, 'weeks').startOf('day').unix(),
        endDate: moment().endOf('day').unix(),
        page: 1,
    };

    const [filter, setFilter] = useState<Filter>(initialFilter);

    const { state } = useAsync({
        promise: getNotificationHistoryList,
        param: {
            keyword: filter.keyword || undefined,
            unpaidTypes: filter.type.length > 0 ? filter.type.toString() : undefined,
            startDate: filter.startDate,
            endDate: filter.endDate,
            page: filter.page,
            pageSize: 15,
        },
        deps: [filter],
        immediate: true,
        keepState: true,
        reject: err => console.error(err),
    });

    const data = state.response ?? DEFAULT_DATA;

    const triggerDownload = useAsyncExcel({
        promise: postNotificationHistoryExcel,
        param: {
            keyword: filter.keyword || undefined,
            sendingType: filter.type.length > 0 ? filter.type.toString() : undefined,
            startDate: filter.startDate,
            endDate: filter.endDate,
            page: filter.page,
            pageSize: 20,
            columnMetas: [
                {
                    key: 'sendingDate',
                    name: t('Send Date'),
                },
                {
                    key: 'sendingTypeStr',
                    name: t('Send Type'),
                },
                {
                    key: 'patientId',
                    name: t('Patient Id'),
                },
                {
                    key: 'patientName',
                    name: t('Patient name'),
                },
                {
                    key: 'departmentName',
                    name: t('Dept'),
                },
                {
                    key: 'message',
                    name: t('Send Text'),
                },
            ],
            zipFileName: 'alarmTalk',
            fileName: 'alarmTalk',
        },
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            {medicalCenterName ? (
                <>
                    <SearchGroup
                        options={options}
                        initialFilter={initialFilter}
                        onSubmit={(filter: Filter) => {
                            setFilter(filter);
                        }}
                        onRefreshClick={() => {
                            setFilter(initialFilter);
                        }}
                        onDownloadClick={handleDownloadClick}
                    />
                    <HistoryList
                        centerName={medicalCenterName}
                        data={data}
                        onPageChange={(page: number) => {
                            setFilter({ ...filter, page });
                        }}
                    />
                </>
            ) : (
                <div className="d-flex flex-center h-90 fw-bold fs-5">{t('Please set the medical center.')}</div>
            )}
            {children}
        </WidgetCard>
    );
};

export default NotificationHistory;
