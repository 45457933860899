import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GeofenceLayer, Map, RotatedImageOverlay } from '@components';
import Control from 'react-leaflet-control';
import { useSelector } from 'react-redux';
import useAsync from '@hooks/useAsync';
import { fetchGeofenceList } from '@api/common/geofence';
import { useSettings } from '../../util/useSettings';
import L from 'leaflet';
import GeofenceInfoPopup from './Component/GeofenceInfoPopup';
import WidgetCard from '../../Components/WidgetCard';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import { selectFirstFloor } from '@reducer/Common/FloorInfo';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { checkLostSignal, getPermittedState, isIn } from '@util/mappInfo';

const GeofenceStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;
    const mapRef = useRef();
    const widgetRef = useRef();
    const [floorInfo, setFloorInfo] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const { floorList } = useSelector(state => state.FloorInfo);
    const { floor } = selectFirstFloor(floorList);
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const [geofenceInOutState, setGeofenceInOutState] = useState({});
    const [tagListByFloor, setTagListByFloor] = useState([]);
    const handleFloorChange = selects => {
        if (selects) {
            setFloorInfo(selects);
        }
    };

    useEffect(() => {
        if (floorInfo?.floorId) {
            getGeofenceList({ floor: floorInfo.floorId });
            const map = mapRef.current.leafletElement;
            if (map) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        } else {
            const currentFloor = settings?.floor ? floorList.find(e => e.floorId === settings.floor) : floor;
            setFloorInfo(currentFloor);
        }
    }, [floorInfo]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            setGeofenceInfo(
                response.rows.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                }),
            );
        }
    }, [getGeofenceInfo, geofenceInOutState]);

    const socketFilterConfig = useMemo(() => [], []);

    const getGeofenceInOutState = compact => {
        setGeofenceInOutState(prev => {
            const geofenceAuth = { ...prev };
            compact.forEach(v => {
                const { isIn, permitted, target, lostSignal, fcNum } = v;
                if (!lostSignal && isIn) {
                    if (!geofenceAuth[fcNum]) {
                        geofenceAuth[fcNum] = { authorized: [], unAuthorized: [] };
                    }
                    if (!geofenceAuth[fcNum][permitted].includes(target)) {
                        geofenceAuth[fcNum][permitted].push(target);
                    }
                }
                if (lostSignal || !isIn) {
                    if (geofenceAuth[fcNum]) {
                        geofenceAuth[fcNum][permitted] = geofenceAuth[fcNum][permitted].filter(v => v !== target);
                    }
                }
            });
            return geofenceAuth;
        });
        setTagListByFloor(compact.filter(v => !v.lostSignal));
    };

    useSocketEvent({
        filterConfig: socketFilterConfig,
        name: EVENT_TYPE_LOCATION,
        handler: data => {
            const compact = [];
            if (data) {
                data.forEach(v => {
                    const { geofences } = v;
                    if (geofences) {
                        geofences.forEach(geo => {
                            compact.push({
                                floor: v.currentFloor?.floorId,
                                targetName: v.target.targetName,
                                category: v.targetCategory?.categoryCode,
                                fcNum: geo.fcNum,
                                fcName: geo.fcName,
                                isIn: isIn(geo.inOutState),
                                inTime: v.currentFloor?.inUnixTime,
                                permitted: getPermittedState(geo?.permitted),
                                target: v.target.targetNum,
                                lostSignal: checkLostSignal(v.targetState.lostSignal),
                            });
                        });
                    }
                });

                getGeofenceInOutState(compact);
            }
        },
        enableBuffer: true,
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} bodyClassName={'p-1'} {...restProps} ref={widgetRef}>
            <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                <Control position="topleft">
                    <SingleTreeSelect
                        data={floorList}
                        title={'Category'}
                        valueKey={'floorId'}
                        labelKey={'floorName'}
                        parentKey={'upperFloorId'}
                        selected={floorInfo?.floorId}
                        onChange={handleFloorChange}
                        isMap
                    />
                </Control>
                {floorInfo?.imgURL && (
                    <RotatedImageOverlay
                        key={floorInfo?.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={e => {
                            const { target: layer } = e;
                            layer._map.fitBounds(layer.getBounds());
                        }}
                    />
                )}
                {geofenceInfo.length > 0 && (
                    <GeofenceLayer
                        geofenceList={geofenceInfo}
                        authState
                        handleClick={geofence => setSelectedGeofence(geofence)}
                    />
                )}

                <GeofenceInfoPopup
                    widgetRef={widgetRef}
                    mapRef={mapRef}
                    modal={Object.keys(selectedGeofence).length > 0}
                    toggleModal={() => setSelectedGeofence({})}
                    selectedEvent={selectedGeofence}
                    geofenceInOutState={tagListByFloor}
                />
            </Map>
            {children}
        </WidgetCard>
    );
};

export default GeofenceStatus;
