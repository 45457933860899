import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import Draggable from '@components/Draggable';
import moment from 'moment';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Table from '@components/Table';
import { useGeofenceTargetInfoColumns } from '@util/grid/column';
import { useSelector } from 'react-redux';

const PAGE_SIZE = 5;

const GeofenceInfoPopup = ({ modal, toggleModal, widgetRef, mapRef, selectedEvent, geofenceInOutState }) => {
    const t = useTranslation('GeofenceStatus');
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const [itemList, setItemList] = useState({ pageSize: 5, rows: [] });
    const [geofenceInfo, setGeofenceInfo] = useState({
        floorName: '',
    });

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        if (selectedEvent.hasOwnProperty('floor')) {
            setGeofenceInfo({
                ...geofenceInfo,
                floorName: floorList.find(floor => {
                    return floor.floorId === selectedEvent.floor;
                }).floorName,
            });
        }
    }, [selectedEvent]);

    useEffect(() => {
        if (selectedEvent) {
            const filteredTargetList = geofenceInOutState
                ?.filter(v => v.fcNum === selectedEvent.fcNum && !v.lostSignal)
                .map(v => {
                    const category = categoryList.find(category => {
                        return v.category === category.categoryCode;
                    });
                    return {
                        targetName: v.targetName,
                        category: category && category.categoryNamePath,
                        authorized: v.permitted === 'authorized' ? 'Y' : 'N',
                        entryTime: v.inTime ? moment.unix(v.inTime).format('YYYY-MM-DD HH:mm:ss') : '-',
                    };
                });
            setItemList({ ...itemList, rows: filteredTargetList });
        }
    }, [geofenceInOutState, selectedEvent]);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;

        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            defaultPosition={position}
            backgroundMapElement={mapRef.current?.leafletElement}
        >
            <Toast isOpen={modal} className={'popup-open bg-depth-3'} innerRef={popupRef} style={{ height: '330px' }}>
                <ToastHeader toggle={toggleModal} className={'bg-depth-4'}>
                    {t('Geofence Name')} : {selectedEvent.fcName} | {t('Floor')} :{geofenceInfo.floorName}
                </ToastHeader>
                <ToastBody className={'bg-depth-4'}>
                    <Table
                        columns={useGeofenceTargetInfoColumns()}
                        data={{
                            rows: itemList.rows,
                            pageSize: PAGE_SIZE,
                            totalPage: Math.ceil(itemList.rows.length / PAGE_SIZE),
                            totalCount: itemList.rows.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default GeofenceInfoPopup;
