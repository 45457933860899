export const warddept = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward/Dept',
        accessor: 'wardName',
        width: 90,
        Cell: ({ value }: { value: string }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const room = ({ ...restProps } = {}) => {
    return {
        Header: 'Room',
        accessor: 'room',
        width: 90,
        Cell: ({ value }: { value: string }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const bed = ({ ...restProps } = {}) => {
    return {
        Header: 'Bed',
        accessor: 'bed',
        width: 90,
        Cell: ({ value }: { value: string }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const targetName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const mappingDevice = ({ ...restProps } = {}) => {
    return {
        Header: 'Mapping Device',
        accessor: 'mappingDevice',
        width: 200,
        ...restProps,
    };
};

export const actions = ({ ...restProps } = {}) => {
    return {
        Header: 'Actions',
        accessor: 'action',
        headerClassName: 'd-flex justify-content-center',
        className: 'd-flex justify-content-center',
        width: 40,
        ...restProps,
    };
};

export const mapping = ({ ...restProps } = {}) => {
    return {
        Header: 'Mapping',
        accessor: 'mapp',
        headerClassName: 'd-flex justify-content-center',
        className: 'd-flex justify-content-center',
        width: 40,
        ...restProps,
    };
};
